var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { Template } from '../../../../../../common/src/bdd/documents/Template';
import { Countries } from '../../../../../../common/src/bdd/utility/countries';
import { Language } from '../../../../../../common/src/bdd/utility/Language';
import { PDFParser } from '../../../../../../common/src/components/pdfpreview/pdfparser';
import { PDFRender } from '../../../../../../common/src/components/pdfpreview/pdfrender';
import { LocalisationService } from '../../../../../../common/src/modules/localisation/localisation.service';
import { SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppModalConfirmComponent } from '../../../../../../common/src/utility/modal/confirm-modal.component';
import { haveValidLanguageValidator, haveValidCountryValidator } from '../addTemplate/addtemplate.component';
var TemplateComponent = /** @class */ (function () {
    function TemplateComponent(hiero, fb, router, route, localisation, modalService) {
        var _this = this;
        this.hiero = hiero;
        this.fb = fb;
        this.router = router;
        this.route = route;
        this.localisation = localisation;
        this.modalService = modalService;
        this.busy = true;
        this.deleteError = null;
        this.formatter = function (lang) { return lang ? lang : ''; };
        this.search = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) {
                return term.length < 2
                    ? []
                    : _this.fullLanguageList.filter(function (value) { return (value.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null); }, 10);
            }));
        };
        this.countryFormatter = function (country) { return country ? country.label : ''; };
        this.countrySearch = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) {
                return term.length < 2
                    ? []
                    : Countries.GetCountryArray('fr').filter(function (value) { return (value.label.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null); }, 10);
            }));
        };
        this.fullLanguageList = Language.GetAllNativeAndRegularNames();
        this.createForm(null);
    }
    TemplateComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.templateId = this.route.snapshot.paramMap.get('templateId');
        // console.log(this.templateId);
        this.adminSub = this.hiero.WatchAdmin({
            next: function (admin) {
                _this.admin = admin;
                DocumentType.Init(admin.User, true)
                    .then(function (map) {
                    _this.docTypeMap = map;
                    return Template.Load(admin.User, _this.templateId, true);
                })
                    .then(function (template) {
                    _this.template = template;
                    _this.templateSub = _this.template.Watch({
                        next: function (updated) {
                            _this.template = updated;
                            _this.createForm(_this.template.Data);
                            _this.busy = false;
                        }
                    });
                });
            }
        });
    };
    TemplateComponent.prototype.ngOnDestroy = function () {
        if (this.adminSub) {
            this.adminSub.unsubscribe();
        }
        if (this.templateSub) {
            this.templateSub.unsubscribe();
        }
    };
    TemplateComponent.prototype.getDocTypeFor = function (code, documentTypeId) {
        return this.docTypeMap.GetDocumentName(code, documentTypeId);
    };
    TemplateComponent.prototype.getCountryName = function (code) {
        return Countries.GetCountryMap('fr').get(code);
    };
    TemplateComponent.prototype.createForm = function (data) {
        var _this = this;
        if (!data) {
            data = {
                srcLanguageIso639: '',
                destLanguageIso639: '',
                srcCountryCode: '',
                priceHT: 0,
                documentTypeId: '',
                config: null
            };
        }
        var country = {
            value: data.srcCountryCode,
            label: Countries.GetCountryMap('fr').get(data.srcCountryCode)
        };
        this.updateForm = new SubmitForm(this.fb, [
            {
                name: 'srcCode',
                value: Language.GetNameFromISO639(data.srcLanguageIso639),
                validators: [haveValidLanguageValidator('srcCode')],
                type: 'text',
                title: 'Langue d\'origine',
                autocomplete: 'off',
                placeholder: 'Commencez à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validLanguage',
                        message: 'Merci de sélectionner la langue de la liste défilante !',
                    },
                ]
            },
            {
                name: 'srcCountry',
                value: country,
                validators: [haveValidCountryValidator('srcCountry')],
                type: 'text',
                title: 'Pays d\'origine',
                autocomplete: 'off',
                placeholder: 'Taper le nom du pays...',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validCountry',
                        message: 'Merci de sélectionner le pays de la liste défilante !',
                    },
                ]
            },
            {
                name: 'dstCode',
                value: Language.GetNameFromISO639(data.destLanguageIso639),
                validators: [haveValidLanguageValidator('dstCode')],
                type: 'text',
                title: 'Traduire vers...',
                autocomplete: 'off',
                placeholder: 'Commencez à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validLanguage',
                        message: 'Merci de sélectionner la langue de la liste défilante !',
                    },
                ]
            },
            {
                name: 'type',
                value: '',
                validators: [],
                type: 'text',
                title: 'Type de document',
                autocomplete: 'off',
                placeholder: '',
                help: 'Le type de document representé par ce document',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            },
            {
                name: 'price',
                value: data.priceHT,
                validators: [Validators.required, Validators.min(0.1)],
                type: 'number',
                title: 'Prix HT',
                autocomplete: 'off',
                placeholder: '',
                min: 0.1,
                help: 'Le prix HT du document percu par le traducteur pour une prestation de ce type',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'min',
                        message: 'Minimum de 0.1€ obligatoire',
                    },
                ]
            }
        ], 
        // Submit callback
        function (changes) {
        }, 
        // Success callback
        function () {
            // What to do with login success ?
            _this.busy = false;
        }, 
        // Fail callback
        function (err) {
            // What to do with login failuer
            console.error(err);
            _this.busy = false;
        }, 
        // Changes callback
        null);
        this.setupDropdown(data.documentTypeId);
        // console.log(data);
        if (data && data.config) {
            this.pdfRender = new PDFRender(data.config);
        }
    };
    TemplateComponent.prototype.setupDropdown = function (documentTypeId) {
        var _this = this;
        var docTypes = [];
        var selectedIndex = 0;
        if (this.docTypeMap) {
            this.docTypeMap.ToArray.forEach(function (docType) {
                var docName = docType.label.find(function (label) { return label.code === 'fr'; });
                if (docName) {
                    docTypes.push({
                        name: docName.value,
                        data: docType._id
                    });
                }
                else {
                    docTypes.push({
                        name: 'Pas de nom: ' + docType._id,
                        data: docType._id
                    });
                }
                if (docType._id === documentTypeId) {
                    _this.selectedDocType = docType._id;
                    selectedIndex = docTypes.length - 1;
                }
            });
        }
        this.documentTypeDropdown = {
            choices: docTypes,
            selectedIndex: selectedIndex,
            title: '',
            input_title: 'Type de document',
            hasButton: false,
            button_title: '',
            help: ''
        };
    };
    TemplateComponent.prototype.selectDocumentType = function (data) {
        this.selectedDocType = data.data;
    };
    TemplateComponent.prototype.update = function () {
        return __awaiter(this, void 0, void 0, function () {
            var src, dst, srcCountryData, price, srcCode, dstCode, srcCountry, priceNum, t, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        src = this.updateForm.GetValue('srcCode');
                        dst = this.updateForm.GetValue('dstCode');
                        srcCountryData = this.updateForm.GetValue('srcCountry');
                        price = this.updateForm.GetValue('price');
                        srcCode = Language.GetISO639(src);
                        dstCode = Language.GetISO639(dst);
                        srcCountry = srcCountryData.value;
                        /// VALIDIITY
                        if (!this.updateForm.CheckValidity()) {
                            return [2 /*return*/];
                        }
                        if (!srcCode) {
                            this.updateForm.SetGeneralError("Error: missing info: srcCode");
                        }
                        if (!dstCode) {
                            this.updateForm.SetGeneralError("Error: missing info: dstCode");
                        }
                        if (!srcCountry) {
                            this.updateForm.SetGeneralError("Error: missing info: srcCountry");
                        }
                        if (!this.selectedDocType) {
                            this.updateForm.SetGeneralError("Error: missing info: this.selectedDocType");
                        }
                        if (!price) {
                            this.updateForm.SetGeneralError("Error: missing info: price");
                        }
                        if (!this.pdfRender) {
                            this.updateForm.SetGeneralError("Error: missing info: this.pdfRender");
                        }
                        if (!srcCode || !dstCode || !srcCountry || !this.selectedDocType || !price || !this.pdfRender) {
                            console.log(srcCode);
                            console.log(dstCode);
                            console.log(srcCountry);
                            console.log(this.selectedDocType);
                            console.log(price);
                            return [2 /*return*/];
                        }
                        this.busy = true;
                        this.pdfRender.clearHighlights();
                        priceNum = parseFloat(price);
                        t = {
                            srcLanguageIso639: srcCode,
                            srcCountryCode: srcCountry,
                            destLanguageIso639: dstCode,
                            documentTypeId: this.selectedDocType,
                            priceHT: priceNum,
                            config: this.pdfRender.Data
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.template.Update(t)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        this.updateForm.SetGeneralError(err_1.message);
                        return [3 /*break*/, 5];
                    case 4:
                        this.busy = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    TemplateComponent.prototype.remove = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef, result, _a, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        modalRef = this.modalService.open(AppModalConfirmComponent, {
                            centered: true,
                        });
                        modalRef.componentInstance.header = 'Confirmation';
                        modalRef.componentInstance.message = 'Vous êtes sur de vouloir supprimer ce template ?';
                        modalRef.componentInstance.ok = 'Oui';
                        modalRef.componentInstance.cancel = 'Non';
                        result = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, modalRef.result];
                    case 2:
                        result = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        if (!result) return [3 /*break*/, 9];
                        this.busy = true;
                        this.deleteError = null;
                        _b.label = 5;
                    case 5:
                        _b.trys.push([5, 7, 8, 9]);
                        return [4 /*yield*/, this.template.Remove()];
                    case 6:
                        _b.sent();
                        this.router.navigate(['/app', 'templates']);
                        return [3 /*break*/, 9];
                    case 7:
                        err_2 = _b.sent();
                        this.deleteError = err_2.message;
                        return [3 /*break*/, 9];
                    case 8:
                        this.busy = false;
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    TemplateComponent.prototype.dropped = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var droppedFile, fileEntry, pdfRender, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (files.length === 0) {
                            return [2 /*return*/];
                        }
                        droppedFile = files[0];
                        if (!droppedFile.fileEntry.isFile) return [3 /*break*/, 4];
                        fileEntry = droppedFile.fileEntry;
                        this.busy = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, PDFParser.Parse(fileEntry)];
                    case 2:
                        pdfRender = _a.sent();
                        this.pdfRender = pdfRender;
                        this.busy = false;
                        return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        console.warn(err_3.message);
                        this.busy = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TemplateComponent.prototype.fileOver = function (event) {
        console.log(event);
    };
    TemplateComponent.prototype.fileLeave = function (event) {
        console.log(event);
    };
    /// Config
    TemplateComponent.prototype.downloadJson = function () {
        var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.pdfRender.Data));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', 'hiero_pdf_config.json');
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };
    TemplateComponent.prototype.droppedJson = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var droppedFile, fileEntry;
            var _this = this;
            return __generator(this, function (_a) {
                if (files.length === 0) {
                    return [2 /*return*/];
                }
                droppedFile = files[0];
                // Is it a file?
                if (droppedFile.fileEntry.isFile) {
                    fileEntry = droppedFile.fileEntry;
                    this.busy = true;
                    try {
                        fileEntry.file(function (fileData) {
                            var fileReader = new FileReader();
                            fileReader.onload = function () {
                                // Step 4:turn array buffer into typed array
                                var json = fileReader.result;
                                try {
                                    var ipdf = JSON.parse(json);
                                    _this.pdfRender = new PDFRender(ipdf);
                                }
                                catch (err) {
                                    console.error(err);
                                }
                            };
                            fileReader.onerror = function () {
                                fileReader.abort();
                                console.error("Error reading file");
                            };
                            // Step 3: Read the file as ArrayBuffer
                            fileReader.readAsText(fileData, 'utf-8');
                        });
                        this.busy = false;
                    }
                    catch (err) {
                        console.warn(err.message);
                        this.busy = false;
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    return TemplateComponent;
}());
export { TemplateComponent };
