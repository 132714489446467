var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Language } from '../../../../../../common/src/bdd/utility/Language';
var DocumentTypeComponent = /** @class */ (function () {
    function DocumentTypeComponent(hiero, fb, router, route) {
        var _this = this;
        this.hiero = hiero;
        this.fb = fb;
        this.router = router;
        this.route = route;
        this.busy = true;
        this.formatter = function (lang) { return lang ? lang : ''; };
        this.search = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) {
                return term.length < 2
                    ? []
                    : _this.fullLanguageList.filter(function (value) { return (value.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null); }, 10);
            }));
        };
        this.fullLanguageList = Language.GetAllNativeAndRegularNames();
        this.createForm();
    }
    DocumentTypeComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.docTypeId = this.route.snapshot.paramMap.get('documentTypeId');
        this.adminSub = this.hiero.WatchAdmin({
            next: function (admin) {
                DocumentType.Init(admin.User, true)
                    .then(function (docType) {
                    _this.docTypeColl = docType;
                    _this.busy = false;
                    _this.extract(_this.docTypeId);
                    _this.docTypeSub = _this.docTypeColl.Watch({
                        next: function (updated) {
                            _this.docTypeColl = updated;
                            _this.busy = false;
                            _this.extract(_this.docTypeId);
                        }
                    });
                });
            }
        });
    };
    DocumentTypeComponent.prototype.extract = function (id) {
        this.docType = this.docTypeColl.getDocumentType(id);
    };
    DocumentTypeComponent.prototype.ngOnDestroy = function () {
        if (this.adminSub) {
            this.adminSub.unsubscribe();
        }
        if (this.docTypeSub) {
            this.docTypeSub.unsubscribe();
        }
    };
    DocumentTypeComponent.prototype.getDocTypeFor = function (code, documentType) {
        return this.docTypeColl.GetDocumentName(code, documentType._id);
    };
    DocumentTypeComponent.prototype.createForm = function () {
        var _this = this;
        this.newForm = new SubmitForm(this.fb, [
            {
                name: 'code',
                value: '',
                validators: [],
                type: 'text',
                title: 'Langue',
                autocomplete: '',
                placeholder: 'Commencer à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            },
            {
                name: 'label',
                value: '',
                validators: [],
                type: 'text',
                title: 'Nom (en français)',
                autocomplete: '',
                placeholder: 'Le nom du document dans la langue précisée',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            }
        ], 
        // Submit callback
        function (changes) {
        }, 
        // Success callback
        function () {
            // What to do with login success ?
            _this.busy = false;
        }, 
        // Fail callback
        function (err) {
            // What to do with login failuer
            console.error(err);
            _this.busy = false;
        }, 
        // Changes callback
        null);
    };
    DocumentTypeComponent.prototype.removeLabel = function (label) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.newForm.ClearGeneralError();
                this.busy = true;
                try {
                    this.docTypeColl.RemoveLabel(this.docType._id, label.code);
                }
                catch (err) {
                    this.newForm.SetGeneralError(err.message);
                }
                return [2 /*return*/];
            });
        });
    };
    DocumentTypeComponent.prototype.addLabel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var name, validated, code, codeISO;
            return __generator(this, function (_a) {
                name = this.newForm.GetValue('label');
                if (!name) {
                    return [2 /*return*/];
                }
                validated = name.trim().toLowerCase();
                if (validated.length === 0) {
                    return [2 /*return*/];
                }
                code = this.newForm.GetValue('code');
                if (!code) {
                    return [2 /*return*/];
                }
                codeISO = Language.GetISO639(code);
                this.newForm.ClearGeneralError();
                this.busy = true;
                try {
                    this.docTypeColl.AddLabel(this.docType._id, codeISO, validated);
                    this.newForm.Reset();
                }
                catch (err) {
                    this.newForm.SetGeneralError(err.message);
                }
                return [2 /*return*/];
            });
        });
    };
    return DocumentTypeComponent;
}());
export { DocumentTypeComponent };
