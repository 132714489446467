import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { IUserProfile } from '../../../../../common/src/bdd/interfaces/IUserProfile';
import { User } from '../../../../../common/src/bdd/user/User';
import { ITitleInfo, TitleExtractor } from '../../../../../common/src/utility/title_extractor.class';
import { HieroBDD } from '../../../services/hierobdd.service';


@Component({
  templateUrl: './appbase.component.html'
})

export class AppBaseComponent implements OnInit, OnDestroy {

  user: User;
  private _subscription: Subscription;

  private _titleExtractor: TitleExtractor;
  titles: ITitleInfo[] = [];
  profile: IUserProfile;

  private _routerSubs: Subscription;

  constructor(
    private hiero: HieroBDD,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    this._titleExtractor = new TitleExtractor(route,
      (key: string) => {
        // Replace strings here if necessary
        return '';
      },
      (titles: ITitleInfo[]) => {
        this.titles = titles;
      }
    );

    
  }

  ngOnInit() {
    this._subscription = this.hiero.Auth.WatchUser({
      next: (user: User) => {
        this.user = user;
        if (this.user) {
          this.user.WatchProfile({
            next: (profile: IUserProfile) => {
              this.profile = profile;
            }
          });
        }
      }
    });

    this._titleExtractor.update();
    this._routerSubs = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._titleExtractor.update();
      }
    });
  }

  getTitle(): string {
    if (this.titles && this.titles.length > 0) {
      return this.titles[0].title;
    } else {
      return '';
    }
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this._routerSubs.unsubscribe();
  }

  async logout() {
    await this.hiero.Auth.logout();
    this.router.navigate(['/']);
  }
}
