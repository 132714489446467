// tslint:disable: quotemark

module.exports = {
  fr: {
    login_caption_title: "Hierø - Plateforme traducteur",
    login_caption_subtitle: "Logiciel de service de mise en relation entre clients et traducteurs.",
    login_main_logo: "Hierø",
    login_main_title: "Plateforme traducteur",
    login_main_subtitle: "Accédez à vos missions et éditeur de document avec la plateforme traducteur Hiero !",
    login_main_connect: "Se connecter",
    login_main_email: "Email",
    login_main_email_placeholder: "Renseignez votre adresse email",
    login_main_email_help: "Merci de vous identifier avec votre compte email",
    login_main_password: "Mot de passe",
    login_main_password_placeholder: "Merci de renseigner votre mot de passe",
    login_main_password_help: "",
    login_main_button: "Se connecter",
    login_main_to_signup: "Pas encore inscrit ? Cliquez ici pour vous inscrire.",
    login_main_copyright: "© Hierø - By'Me Univers 2019",

    login_error_required_field: "Champ obligatoire",
    login_error_invalid_email: "Le email saisi n'est pas valide",
    login_error_email_not_found: "Email pas trouvé sur notre système",


    signup_caption_title: "Hierø - Plateforme traducteur",
    signup_caption_subtitle: "Référencez votre agence sur notre application, et gagnez en visibilité !",
    signup_main_logo: "Hierø",
    signup_main_title: "Inscription",
    signup_main_subtitle: "Créez votre compte, rejoignez notre plateforme et rendez-vous visible à nos nombreux utilisateurs !",
    signup_main_email: "Email",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Mot de passe",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirmation",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Votre nom de famille",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Votre prénom",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Numéro téléphone",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "S'inscrire",
    signup_main_to_signup: "Déjà membre ? Cliquez ici pour vous connecter.",
    signup_main_copyright: "© Hierø - By'Me Univers 2019",

    signup_error_required_field: "Champ obligatoire",
    signup_error_invalid_email: "Le email saisi n'est pas valide",
    signup_error_email_not_found: "Email pas trouvé sur notre système",
    signup_error_min_password: "Minimum 8 caractères",
    signup_error_max_password: "Maximum 30 caractères",
    signup_error_passwords_do_not_match: "Votre mot de passe ne corresponde pas",

    main_header_title: "Hierø - ",
    main_header_subtitle: "Plateforme traducteur ",
    main_header_user_menu_logout: "Se déconnecter",

    main_left_menu_title: "Traducteur assermenté",

    main_left_menu_section_navigation: "Navigation",
    main_left_menu_section_navigation_missions: "Mes missions",
    main_left_menu_section_parameters: "Mes paramètres",
    main_left_menu_section_parameters_agency: "Mon agence",
    main_left_menu_section_parameters_services: "Mes services",
    main_left_menu_section_parameters_user: "Compte utilisateur",
    main_left_menu_section_parameters_support: "Support",

    agency_setup_title: "À propos de votre agence",
    agency_setup_explanation: "Hierø propose vos services aux clients proches de vos locaux.<br>Merci de nous préciser le nom de votre entreprise et votre adresse d'opération. <br>Ces informations seront proposées aux clients lorsqu'ils cherchent un traducteur.",
    agency_setup_companyname: "Raison sociale",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help: "Le nom de votre entreprise qui sera proposé aux clients.",
    agency_setup_address: "Adresse",
    agency_setup_address_placeholder: "",
    agency_setup_address_help: "Commencez à écrire votre adresse, et sélectionnez l'adresse qui sera proposé dans le menu défilant.",
    agency_setup_addresscompl: "Complément d'adresse",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help: "Batiment, escalier, digicode, etc. Lorsque vos clients viennent cherchez leurs documents ils devront avoir toutes les informations nécessaires.",
    agency_setup_tel: "Télephone",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help: "Le numéro téléphonique à utiliser pour toute correspondence avec vos clients.",
    agency_setup_email: "Email",
    agency_setup_email_placeholder: "",
    agency_setup_email_help: "L'adresse email à utiliser pour toute correspondence avec vos clients. Renseignez une adresse différente s'il s'agit d'une adresse differente à votre adresse d'inscription sur Hierø.",
    agency_setup_save: "Sauvegarder",
    agency_setup_error_required_field: "Champ obligatoire",
    agency_setup_error_invalid_email: "Le email saisi n'est pas valide.",
    agency_setup_error_valid_address: "Vous devez sélectionner une adresse proposé dans le menu défilant.",

    section_agency_title: "Mon agence",
    section_services_title: "Mes services",
    section_account_title: "Mon compte",
    section_support_title: "Support",
    section_tasks_title: "Mes missions",
    section_task_title: "Effectuer une mission",
    section_document_title: "Éditer un document",

    notifications_new_task: "Vous avez une nouvelle mission!",
    notifications_task_confirmed: "Une mission vient d'être confirimée !",
    notifications_task_validated: "Une mission vient d'être validée par le client!",

    tasks_filters: "Filtres",
    tasks_sent_on: "Envoyé le",
    tasks_src_lang: "Langue d'origine",
    tasks_src_country: "Pays d'origine",
    tasks_dst_country: "Vers",
    tasks_state: "État",
    tasks_price: "Prix (HT)",

    state_cancelled: "Annulé par le client",
    state_waiting_for_translator: 'Nouveau',
    state_waiting_for_payment: 'En attente de paiement',
    state_refused_by_translator: 'Mission refusée',
    state_translating: 'En cours de traduction',
    state_waiting_for_client_validation: 'Finie. En attente de validation par le client.',
		state_validated: 'Validée par le client',    
		
		prestation_breadcrumb_tasks: 'Mes missions',

		prestation_order_number: 'N° de commande :',
		prestation_next_action_title: "Prochaines actions",
		prestation_next_action_accept_order: "Accepter la commande",
		prestation_next_action_accept_order_expl: "Cliquez ici pour accepter la commande. Le client va être amené à proceder avec le paiement. Une fois que le paiement sera effectué, vous pouvez commencer la traduction.",
		prestation_next_action_reject_order: "Refuser la commande",
		prestation_next_action_reject_order_expl: "Cliquez ici pour refuser la commande.",
		prestation_next_action_rejected: "Vous avez refusé cette commande.",
		prestation_next_action_waiting_for_payment: "En attente du paiement par le client.",
		prestation_next_action_translate_expl: "Merci de procéder à la traduction de chaque document de cette commande, en cliqant le bouton 'Traduire'. Une fois que tous les documents soient prêts, cliquez le bouton 'Envoyer au client'. Pour toutes questions ou précisions, vous pouvez échanger directement avec le client via le messagerie.",
		prestation_next_action_translate_all_done_expl: "Vous avez validé tous les documents de cette commande.",
		prestation_next_action_translate_confirm: "Envoyer au client pour validation",
		prestation_next_action_waiting_for_validation: "Le client est en train de valider la traduction.",
		prestation_next_action_validated: "Commande validée par le client.",
		prestation_order_details: "Détails de la commande :",
		prestation_order_from: "De",
		prestation_order_to: "à",
		prestation_order_country_origin: "Pays d'origine :",
		prestation_order_document_count: "Documents à traduire :",
		prestation_order_price: "Prix (HT) :",
		prestation_order_date: "Commande créée le :",
		prestation_order_documents_title: "Documents à traiter :",
		prestation_order_document_type: "Type de document",
		prestation_order_document_originals: "Originaux",
		prestation_order_document_translated: "Traduit(s)",
		prestation_order_document_not_yet_translated: "Pas encore de traduction",
		prestation_order_document_translate: "Traduire !",
		prestation_order_messaging_title: "Messagerie",
		prestation_order_messaging_send: "Envoyer",
		prestation_order_messaging_client: "Client :",
		prestation_order_messaging_me: "Moi :",
		prestation_order_confirm_ok: "Ok",
		prestation_order_confirm_cancel: "Annuler",
		prestation_order_validate_title: "Confirmer",
		prestation_order_validate_msg: "Vous êtes sur de vouloir valider cette commande ? Le client va recevoir une notification et aura désormais accès aux documents traduits.",
		prestation_order_accept_title: "Confirmer",
		prestation_order_accept_msg: "Vous êtes sur de vouloir accepter cette commande ?",
		prestation_order_refuse_title: "Confirmer",
		prestation_order_refuse_msg: "Vous êtes sur de vouloir refuser cette commande ?",

		prestation_document_validate: "Valider la traduction !",
		prestation_document_show_original: "Visionner l'original dans un autre onglet :",
		prestation_document_edit: "Éditeur de document",
		prestation_document_edit_expl: "Le texte dans le champs dessus et sousligné dans le document ci-dessous. En saisissant le text traduit dans le champ, le document sera automatiquement mis à jour. Utiliser les flèches pour naviguer dans le document.",
		prestation_document_get_browser: "Vous ne voyez pas de PDF ? Merci d'utiliser un navigateur récent comme",
		prestation_document_button_reset: "Remettre à zéro le document",
		prestation_document_confirm_title: "Confirmation",
		prestation_document_confirm_message: "Vous êtes sur de vouloir remettre à zéro votre traduction ?  Vous allez perdre toutes tes modifications !",
		prestation_document_confirm_oui: "Oui",
		prestation_document_confirm_non: "Non",


		account_expl: "Vos coordonnées personnels afin de pouvoir vous contacter si necessaire.",
		account_save: "Sauvegarder",
		account_surname: "Votre nom de famille",
    account_surname_placeholder: "",
    account_surname_help: "",
    account_name: "Votre prénom",
    account_name_placeholder: "",
    account_name_help: "",
    account_telephone: "Numéro téléphone",
    account_telephone_placeholder: "",
		account_telephone_help: "",
		account_error_required: "Champ obligatoire",

		agency_expl_1_title: "Raison sociale",
		agency_expl_1_expl: "Précisez le nom de votre entreprise afin que vos clients puissent vous découvrir.",
		agency_expl_2_title: "Géolocalisation",
		agency_expl_2_expl: "L'application nécessite vos coordonnées afin de vous mettre en relation avec les clients les plus proches de vous.",
		agency_expl_3_title: "Activation du compte",
		agency_expl_3_expl: "Votre profil sera visible en ligne une fois la sauvegarde des les informations effectuée.",
				
		services_add_button: "Ajouter une nouvelle offre...",
		services_none_yet: "Afin d'être visible sur la plateforme Hierø, il faut préciser les types de prestations que vous gerez.",
		services_add_expl: "Afin d'être proposé par l'appli Hierø, merci de préciser les types de traduction que vous fournissez, puis cliquer sur 'Ajouter'.",
		services_add_src_lang: "Langue d'origine",
		services_dst_lang: "Vers",
		services_add: "Ajouter !",
		services_error_add: "Cette combinaison de langues n'est pas encore possible avec Hiero. Contactez nous afin d'jouter cette combinasion à notre offre !",
		services_dont_see_title: "Je ne vois pas mes langues ?",		
		services_dont_see_response: "Hierø vous facilite le travail de traduction en vous fournissant des modèles des documents pré-traduits et déjà mis en page. Nous augmentons continuellement notre gamme de documents. Merci de nous contacter à <a href='mailto:contact@Hierø-support.com'>contact@hiero-support.com</a> pour demander d'autres langues.",

		services_list_title: "Votre liste d'offres de service",
		services_expl: "Cliquer sur chaque offre afin de personnaliser les documents que vous gerez. Vous pouvez soit choisir de ne pas accepter un certain type de document d'un pays en question (en désactivant la pastille), soit vous pouvez préciser un prix personnalisé pour traiter ce type de document (en saisissant le prix).",

		services_list_active: "Activé",
		services_list_country: "Pays",
		services_list_document_type: "Type de document",
		services_list_average_price: "Prix moyen (HT)",
		services_list_your_price: "Votre prix (HT)",
		services_list_your_price_reset: "Réinitialiser",
		services_list_delete: "Supprimer",

		support_title: "Nos locaux",
		support_company: "By'Me Univers",
		support_addr1: "15 Avenue de Norvège",
		support_addr2: "91140, Villebon-sur-Yvette",
		support_addr3: "France",
		support_contact: "Contact",
		support_tel: "Tel : (+33) 1 80 38 30 46",
		support_email: "Mail : <a href='mailto:contact@hiero-support.com'>contact@hiero-support.com</a>",

		not_found_title: "Impossible de trouver l'élément",
		not_found_subtitle: "La page que vous essayez d'atteindre n'existe pas",
		not_found_action: "Contactez <a href='mailto:contact@hiero-support.com'>le support</a> si le problème persiste",
		not_found_home: "Accueil",



    AF: "Afghanistan",
		ZA: "Afrique du Sud",
		AL: "Albanie",
		DZ: "Algérie",
		DE: "Allemagne",
		AD: "Andorre",
		AO: "Angola",
		AI: "Anguilla",
		AQ: "Antarctique",
		AG: "Antigua-et-Barbuda",
		AN: "Antilles néerlandaises",
		SA: "Arabie saoudite",
		AR: "Argentine",
		AM: "Arménie",
		AW: "Aruba",
		AU: "Australie",
		AT: "Autriche",
		AZ: "Azerbaïdjan",
		BS: "Bahamas",
		BH: "Bahreïn",
		BD: "Bangladesh",
		BB: "Barbade",
		BY: "Bélarus",
		BE: "Belgique",
		BZ: "Belize",
		BJ: "Bénin",
		BM: "Bermudes",
		BT: "Bhoutan",
		BO: "Bolivie",
		BA: "Bosnie-Herzégovine",
		BW: "Botswana",
		BR: "Brésil",
		BN: "Brunéi Darussalam",
		BG: "Bulgarie",
		BF: "Burkina Faso",
		BI: "Burundi",
		KH: "Cambodge",
		CM: "Cameroun",
		CA: "Canada",
		CV: "Cap-Vert",
		EA: "Ceuta et Melilla",
		CL: "Chili",
		CN: "Chine",
		CY: "Chypre",
		CO: "Colombie",
		KM: "Comores",
		CG: "Congo-Brazzaville",
		KP: "Corée du Nord",
		KR: "Corée du Sud",
		CR: "Costa Rica",
		CI: "Côte d’Ivoire",
		HR: "Croatie",
		CU: "Cuba",
		DK: "Danemark",
		DG: "Diego Garcia",
		DJ: "Djibouti",
		DM: "Dominique",
		EG: "Égypte",
		SV: "El Salvador",
		AE: "Émirats arabes unis",
		EC: "Équateur",
		ER: "Érythrée",
		ES: "Espagne",
		EE: "Estonie",
		VA: "État de la Cité du Vatican",
		FM: "États fédérés de Micronésie",
		US: "États-Unis",
		ET: "Éthiopie",
		FJ: "Fidji",
		FI: "Finlande",
		FR: "France",
		GA: "Gabon",
		GM: "Gambie",
		GE: "Géorgie",
		GS: "Géorgie du Sud et les îles Sandwich du Sud",
		GH: "Ghana",
		GI: "Gibraltar",
		GR: "Grèce",
		GD: "Grenade",
		GL: "Groenland",
		GP: "Guadeloupe",
		GU: "Guam",
		GT: "Guatemala",
		GG: "Guernesey",
		GN: "Guinée",
		GQ: "Guinée équatoriale",
		GW: "Guinée-Bissau",
		GY: "Guyana",
		GF: "Guyane française",
		HT: "Haïti",
		HN: "Honduras",
		HU: "Hongrie",
		BV: "Île Bouvet",
		CX: "Île Christmas",
		CP: "Île Clipperton",
		AC: "Île de l'Ascension",
		IM: "Île de Man",
		NF: "Île Norfolk",
		AX: "Îles Åland",
		KY: "Îles Caïmans",
		IC: "Îles Canaries",
		CC: "Îles Cocos - Keeling",
		CK: "Îles Cook",
		FO: "Îles Féroé",
		HM: "Îles Heard et MacDonald",
		FK: "Îles Malouines",
		MP: "Îles Mariannes du Nord",
		MH: "Îles Marshall",
		UM: "Îles Mineures Éloignées des États-Unis",
		SB: "Îles Salomon",
		TC: "Îles Turks et Caïques",
		VG: "Îles Vierges britanniques",
		VI: "Îles Vierges des États-Unis",
		IN: "Inde",
		ID: "Indonésie",
		IQ: "Irak",
		IR: "Iran",
		IE: "Irlande",
		IS: "Islande",
		IL: "Israël",
		IT: "Italie",
		JM: "Jamaïque",
		JP: "Japon",
		JE: "Jersey",
		JO: "Jordanie",
		KZ: "Kazakhstan",
		KE: "Kenya",
		KG: "Kirghizistan",
		KI: "Kiribati",
		KW: "Koweït",
		LA: "Laos",
		LS: "Lesotho",
		LV: "Lettonie",
		LB: "Liban",
		LR: "Libéria",
		LY: "Libye",
		LI: "Liechtenstein",
		LT: "Lituanie",
		LU: "Luxembourg",
		MK: "Macédoine",
		MG: "Madagascar",
		MY: "Malaisie",
		MW: "Malawi",
		MV: "Maldives",
		ML: "Mali",
		MT: "Malte",
		MA: "Maroc",
		MQ: "Martinique",
		MU: "Maurice",
		MR: "Mauritanie",
		YT: "Mayotte",
		MX: "Mexique",
		MD: "Moldavie",
		MC: "Monaco",
		MN: "Mongolie",
		ME: "Monténégro",
		MS: "Montserrat",
		MZ: "Mozambique",
		MM: "Myanmar",
		NA: "Namibie",
		NR: "Nauru",
		NP: "Népal",
		NI: "Nicaragua",
		NE: "Niger",
		NG: "Nigéria",
		NU: "Niue",
		NO: "Norvège",
		NC: "Nouvelle-Calédonie",
		NZ: "Nouvelle-Zélande",
		OM: "Oman",
		UG: "Ouganda",
		UZ: "Ouzbékistan",
		PK: "Pakistan",
		PW: "Palaos",
		PA: "Panama",
		PG: "Papouasie-Nouvelle-Guinée",
		PY: "Paraguay",
		NL: "Pays-Bas",
		PE: "Pérou",
		PH: "Philippines",
		PN: "Pitcairn",
		PL: "Pologne",
		PF: "Polynésie française",
		PR: "Porto Rico",
		PT: "Portugal",
		QA: "Qatar",
		HK: "R.A.S. chinoise de Hong Kong",
		MO: "R.A.S. chinoise de Macao",
		QO: "Régions éloignées de l’Océanie",
		CF: "République centrafricaine",
		CD: "République démocratique du Congo",
		DO: "République dominicaine",
		CZ: "République tchèque",
		RE: "Réunion",
		RO: "Roumanie",
		GB: "Royaume-Uni",
		RU: "Russie",
		RW: "Rwanda",
		EH: "Sahara occidental",
		BL: "Saint-Barthélemy",
		KN: "Saint-Kitts-et-Nevis",
		SM: "Saint-Marin",
		MF: "Saint-Martin",
		PM: "Saint-Pierre-et-Miquelon",
		VC: "Saint-Vincent-et-les Grenadines",
		SH: "Sainte-Hélène",
		LC: "Sainte-Lucie",
		WS: "Samoa",
		AS: "Samoa américaines",
		ST: "Sao Tomé-et-Principe",
		SN: "Sénégal",
		RS: "Serbie",
		CS: "Serbie-et-Monténégro",
		SC: "Seychelles",
		SL: "Sierra Leone",
		SG: "Singapour",
		SK: "Slovaquie",
		SI: "Slovénie",
		SO: "Somalie",
		SD: "Soudan",
		LK: "Sri Lanka",
		SE: "Suède",
		CH: "Suisse",
		SR: "Suriname",
		SJ: "Svalbard et Île Jan Mayen",
		SZ: "Swaziland",
		SY: "Syrie",
		TJ: "Tadjikistan",
		TW: "Taïwan",
		TZ: "Tanzanie",
		TD: "Tchad",
		TF: "Terres australes et antarctiques françaises",
		IO: "Territoire britannique de l'océan Indien",
		PS: "Territoire palestinien",
		TH: "Thaïlande",
		TL: "Timor oriental",
		TG: "Togo",
		TK: "Tokelau",
		TO: "Tonga",
		TT: "Trinité-et-Tobago",
		TA: "Tristan da Cunha",
		TN: "Tunisie",
		TM: "Turkménistan",
		TR: "Turquie",
		TV: "Tuvalu",
		UA: "Ukraine",
		EU: "Union européenne",
		UY: "Uruguay",
		VU: "Vanuatu",
		VE: "Venezuela",
		VN: "Viêt Nam",
		WF: "Wallis-et-Futuna",
		YE: "Yémen",
		ZM: "Zambie",
		ZW: "Zimbabwe",
  },
  en: {
    login_caption_title: "Hierø - Translator Portal",
    login_caption_subtitle: "Software service for connecting clients and translators",
    login_main_logo: "Hierø",
    login_main_title: "Translator Portal",
    login_main_subtitle: "Access your tasks and document editor using the Hierø translator portal!",
    login_main_connect: "Log in",
    login_main_email: "Email",
    login_main_email_placeholder: "Your email address",
    login_main_email_help: "Use your email address to log in.",
    login_main_password: "Password",
    login_main_password_placeholder: "Please enter your password.",
    login_main_password_help: "",
    login_main_button: "Log in",
    login_main_to_signup: "Do not have an account? Sign up here!",
    login_main_copyright: "© Hierø - By'Me Univers 2019",

    login_error_required_field: "Required field",
    login_error_invalid_email: "The email you provided is not valid.",
    login_error_email_not_found: "This email is not registered.",


    signup_caption_title: "Hierø - Translator Portal",
    signup_caption_subtitle: "List your agency on Hierø and improve visibility!",
    signup_main_logo: "Hierø",
    signup_main_title: "Sign up",
    signup_main_subtitle: "Create your account, join the platform and become more visible to our user base!",
    signup_main_email: "Email",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Password",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirm password",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Surname",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Name",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Telephone number",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Sign up!",
    signup_main_to_signup: "Already have an account? Click here to log in!",
    signup_main_copyright: "© Hierø - By'Me Univers 2019",

    signup_error_required_field: "Required field",
    signup_error_invalid_email: "The email you provided is not valid.",
    signup_error_min_password: "Minimum 8 characters",
    signup_error_max_password: "Maximum 30 characters",
    signup_error_passwords_do_not_match: "Your passwords do not match.",

    main_header_title: "Hierø - ",
    main_header_subtitle: "Translator platform",
    main_header_user_menu_logout: "Log out",

    main_left_menu_title: "Certified translator",

    main_left_menu_section_navigation: "Navigation",
    main_left_menu_section_navigation_missions: "My tasks",
    main_left_menu_section_parameters: "Parameters",
    main_left_menu_section_parameters_agency: "My agency",
    main_left_menu_section_parameters_services: "My services",
    main_left_menu_section_parameters_user: "Account",
    main_left_menu_section_parameters_support: "Support",

    agency_setup_title: "About your agency",
    agency_setup_explanation: "Hierø proposes your services to clients that are nearby to your offices. <br>Please provide the name of your company, as well as your address.<br>This information will be provided to clients while searching for a translator.",
    agency_setup_companyname: "Company name",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help: "The name of your company that will be presented to clients.",
    agency_setup_address: "Address",
    agency_setup_address_placeholder: "",
    agency_setup_address_help: "Start typing your address, then select the correct one in the menu that appears.",
    agency_setup_addresscompl: "Address precisions",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help: "Building, staircase, code, etc. When your clients arrive to recover their documents, they will need all the information necessary to find your offices.",
    agency_setup_tel: "Telephone",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help: "The telephone number to use for correspondence with your clients.",
    agency_setup_email: "Email",
    agency_setup_email_placeholder: "",
    agency_setup_email_help: "The email address to use when corresponding with your clients. If you prefer to use a different email address to the one you use for your Hierø account, please modify it here.",
    agency_setup_save: "Save",
    agency_setup_error_required_field: "Required field",
    agency_setup_error_invalid_email: "The email you provided is not valid.",
    agency_setup_error_valid_address: "You must select a valid address from the dropdown menu",

    section_agency_title: "My agency",
    section_services_title: "My services",
    section_account_title: "Account",
    section_support_title: "Support",
    section_tasks_title: "My tasks",
    section_task_title: "Task",
    section_document_title: "Translate a document",

    notifications_new_task: "You have a new task to accept!",
    notifications_task_confirmed: "A task has just been confirmed, you can start translating!",
    notifications_task_validated: "A task has just been validated by your client!",

    tasks_filters: "Filters",
    tasks_sent_on: "Updated on",
    tasks_src_lang: "Original language",
    tasks_src_country: "Original country",
    tasks_dst_country: "Translate to",
    tasks_state: "Order progress",
    tasks_price: "Price (excl.)",

    state_cancelled: "Canceled by the client",
    state_waiting_for_translator: 'New!',
    state_waiting_for_payment: 'Waiting for payment',
    state_refused_by_translator: 'Refused',
    state_translating: 'Busy translating',
    state_waiting_for_client_validation: 'Done, waiting for client approval',
		state_validated: 'Approved by client',    
		
		prestation_breadcrumb_tasks: 'My tasks',

		prestation_order_number: 'Order number:',
		prestation_next_action_title: "Next steps",
		prestation_next_action_accept_order: "Accept",
		prestation_next_action_accept_order_expl: "Click here to accept the order. The client will be asked to proceed with the payment. Once the payment has been completed, you can start the translation.",
		prestation_next_action_reject_order: "Refuse the order",
		prestation_next_action_reject_order_expl: "Click here to refuse the order.",
		prestation_next_action_rejected: "You refused the order.",
		prestation_next_action_waiting_for_payment: "Waiting for the client to make payment.",
		prestation_next_action_translate_expl: "Please proceed with the translation of each document in this order, by clicking the 'Translate' button. When all documents are ready, click the 'Send to client' button. For any questions or precisions, you can use the chat to confer directly with the client.",
		prestation_next_action_translate_all_done_expl: "You have finished all documents in this order!.",
		prestation_next_action_translate_confirm: "Send to client for verification",
		prestation_next_action_waiting_for_validation: "The client is busy verifying the order.",
		prestation_next_action_validated: "The order was verified by the client!",
		prestation_order_details: "Order details:",
		prestation_order_from: "From",
		prestation_order_to: "to",
		prestation_order_country_origin: "Country of origin :",
		prestation_order_document_count: "Documents to translate :",
		prestation_order_price: "Price (excl.) :",
		prestation_order_date: "Order created on :",
		prestation_order_documents_title: "Documents to translate :",
		prestation_order_document_type: "Document type",
		prestation_order_document_originals: "Originals",
		prestation_order_document_translated: "Translated",
		prestation_order_document_not_yet_translated: "No translation yet!",
		prestation_order_document_translate: "Translate!",
		prestation_order_messaging_title: "Chat",
		prestation_order_messaging_send: "Send",
		prestation_order_messaging_client: "Client :",
		prestation_order_messaging_me: "Me :",
		prestation_order_confirm_ok: "Ok",
		prestation_order_confirm_cancel: "Cancel",
		prestation_order_validate_title: "Confirm",
		prestation_order_validate_msg: "Are you sure you want to finalise this order? The client will receive a notification and will have access to the the translated documents.",
		prestation_order_accept_title: "Confirm",
		prestation_order_accept_msg: "Are you sure you wish to accept this order?",
		prestation_order_refuse_title: "Confirm",
		prestation_order_refuse_msg: "Are you sure you wish to refuse this order?",

		prestation_document_validate: "Finalise and save!",
		prestation_document_show_original: "Show the original in another tab :",
		prestation_document_edit: "Document editor",
		prestation_document_edit_expl: "The text in the field above is highlighted in the document below. When you modify the text above, the document will automatically be updated. Use the arrows to navigate between phrases in the document.",
		prestation_document_get_browser: "You do not see the translated PDF? Please use an up to date browser like ",
		prestation_document_button_reset: "Reset the document",
		prestation_document_confirm_title: "Confirmation",
		prestation_document_confirm_message: "Are you sure you want to reset this document? You will lose all your changes!",
		prestation_document_confirm_oui: "Yes",
		prestation_document_confirm_non: "No",

		account_expl: "Your personal contact details in the event we need to get in touch.",
		account_save: "Save",
		account_surname: "Surname",
    account_surname_placeholder: "",
    account_surname_help: "",
    account_name: "Name",
    account_name_placeholder: "",
    account_name_help: "",
    account_telephone: "Telephone",
    account_telephone_placeholder: "",
		account_telephone_help: "",
		account_error_required: "Required",

		agency_expl_1_title: "Business name",
		agency_expl_1_expl: "Specify your business name so that your clients can find you.",
		agency_expl_2_title: "Geolocalisation",
		agency_expl_2_expl: "The application needs your coordonates in order to put you in touch with clients close to you.",
		agency_expl_3_title: "Activate your account",
		agency_expl_3_expl: "Your profile will be visible once you have saved the information.",

		services_add_button: "Add a new offer...",
		services_none_yet: "In order to be visible in the Hierø app, you need to specify what kind of translations you provide.",
		services_add_expl: "Please select the language you can translate from and to, then click 'Add'.",
		services_add_src_lang: "From",
		services_dst_lang: "To",
		services_add: "Add!",
		services_error_add: "This combination of languages is not yet supported by Hierø. Please contact us for more information!",
		services_dont_see_title: "Do not see my languages?",		
		services_dont_see_response: "Hierø facilitates your translations by providing ready-made templates for specific documents. We are continually adding to our database, so do not hesitate to contact us at <a href='mailto:contact@Hierø-support.com'>contact@hiero-support.com</a> to support your languages.",

		services_list_title: "Your list of services",
		services_expl: "Click on each service in order to personnalise the documents you will accept. You can either choose to not handle certain document types by disabling the toggle, or you can modify the amount you charge for that document by filling in the field for that document type.",

		services_list_active: "Enabled",
		services_list_country: "Country",
		services_list_document_type: "Document type",
		services_list_average_price: "Average price (excl.)",
		services_list_your_price: "Your price (excl.)",
		services_list_your_price_reset: "Reset",
		services_list_delete: "Remove",

		support_title: "Our offices",
		support_company: "By'Me Univers",
		support_addr1: "15 Avenue de Norvège",
		support_addr2: "91140, Villebon-sur-Yvette",
		support_addr3: "France",
		support_contact: "Contact",
		support_tel: "Tel : (+33) 1 80 38 30 46",
		support_email: "Email : <a href='mailto:contact@hiero-support.com'>contact@hiero-support.com</a>",

		not_found_title: "Element not found",
		not_found_subtitle: "The page you requested does not exist",
		not_found_action: "Contact <a href='mailto:contact@hiero-support.com'>support</a> if the problem persists.",
		not_found_home: "Home",

    AF: "Afghanistan",
		ZA: "South Africa",
		AL: "Albania",
		DZ: "Algeria",
		DE: "Germany",
		AD: "Andorra",
		AO: "Angola",
		AI: "Anguilla",
		AQ: "Antarctica",
		AG: "Antigua and Barbuda",
		AN: "Netherlands Antilles",
		SA: "Saudi Arabia",
		AR: "Argentina",
		AM: "Armenia",
		AW: "Aruba",
		AU: "Australia",
		AT: "Austria",
		AZ: "Azerbaijan",
		BS: "Bahamas",
		BH: "Bahrain",
		BD: "Bangladesh",
		BB: "Barbados",
		BY: "Belarus",
		BE: "Belgium",
		BZ: "Belize",
		BJ: "Benin",
		BM: "Bermuda",
		BT: "Bhutan",
		BO: "Bolivia",
		BA: "Bosnia and Herzegovina",
		BW: "Botswana",
		BR: "Brazil",
		BN: "Brunei",
		BG: "Bulgaria",
		BF: "Burkina Faso",
		BI: "Burundi",
		KH: "Cambodia",
		CM: "Cameroon",
		CA: "Canada",
		CV: "Cape Verde",
		EA: "Ceuta and Melilla",
		CL: "Chile",
		CN: "China",
		CY: "Cyprus",
		CO: "Colombia",
		KM: "Comoros",
		CG: "Congo-Brazzaville",
		KP: "North Korea",
		KR: "South Korea",
		CR: "Costa Rica",
		CI: "Ivory Coast",
		HR: "Croatia",
		CU: "Cuba",
		DK: "Denmark",
		DG: "Diego Garcia",
		DJ: "Djibouti",
		DM: "Dominica",
		EG: "Egypt",
		SV: "El Salvador",
		AE: "United Arab Emirates",
		EC: "Ecuador",
		ER: "Eritrea",
		ES: "Spain",
		EE: "Estonia",
		VA: "Vatican",
		FM: "Micronesia",
		US: "United States of America",
		ET: "Ethiopia",
		FJ: "Fiji",
		FI: "Finland",
		FR: "France",
		GA: "Gabon",
		GM: "Gambia",
		GE: "Georgia",
		GS: "South Georgia and the South Sandwich Islands",
		GH: "Ghana",
		GI: "Gibraltar",
		GR: "Greece",
		GD: "Granada",
		GL: "Greenland",
		GP: "Guadeloupe",
		GU: "Guam",
		GT: "Guatemala",
		GG: "Guernsey",
		GN: "Guinea",
		GQ: "Equatorial Guinea",
		GW: "Guinea-Bissau",
		GY: "Guyana",
		GF: "French Guyana",
		HT: "Haiti",
		HN: "Honduras",
		HU: "Hungary",
		BV: "Bouvet Island",
		CX: "Christmas Island",
		CP: "Clipperton Island",
		AC: "Ascension Island",
		IM: "Isle of Man",
		NF: "Norfolk Island",
		AX: "Åland Islands",
		KY: "Cayman Islands",
		IC: "Canary Islands",
		CC: "Cocos (Keeling) Islands",
		CK: "Cook Islands",
		FO: "Faroe Islands",
		HM: "Heard Island and McDonald Islands",
		FK: "Falkland Islands",
		MP: "Northern Mariana Islands",
		MH: "Marshall Islands",
		UM: "United States Minor Outlying Islands",
		SB: "Solomon Islands",
		TC: "Turks and Caicos Islands",
		VG: "British Virgin Islands",
		VI: "U.S. Virgin Islands",
		IN: "India",
		ID: "Indonesia",
		IQ: "Iraq",
		IR: "Iran",
		IE: "Ireland",
		IS: "Iceland",
		IL: "Israel",
		IT: "Italy",
		JM: "Jamaica",
		JP: "Japan",
		JE: "Jersey",
		JO: "Jordan",
		KZ: "Kazakhstan",
		KE: "Kenya",
		KG: "Kyrgyzstan",
		KI: "Kiribati",
		KW: "Kuwait",
		LA: "Laos",
		LS: "Lesotho",
		LV: "Latvia",
		LB: "Lebanon",
		LR: "Liberia",
		LY: "Libya",
		LI: "Liechtenstein",
		LT: "Lithuania",
		LU: "Luxembourg",
		MK: "Macedonia",
		MG: "Madagascar",
		MY: "Malaysia",
		MW: "Malawi",
		MV: "Maldives",
		ML: "Mali",
		MT: "Malta",
		MA: "Morocco",
		MQ: "Martinique",
		MU: "Mauritius",
		MR: "Mauritania",
		YT: "Mayotte",
		MX: "Mexico",
		MD: "Moldova",
		MC: "Monaco",
		MN: "Mongolia",
		ME: "Montenegro",
		MS: "Montserrat",
		MZ: "Mozambique",
		MM: "Myanmar",
		NA: "Namibia",
		NR: "Nauru",
		NP: "Nepal",
		NI: "Nicaragua",
		NE: "Niger",
		NG: "Nigeria",
		NU: "Niue",
		NO: "Norway",
		NC: "New Caledonia",
		NZ: "New Zealand",
		OM: "Oman",
		UG: "Uganda",
		UZ: "Uzbekistan",
		PK: "Pakistan",
		PW: "Palau",
		PA: "Panama",
		PG: "Papua New Guinea",
		PY: "Paraguay",
		NL: "Netherlands",
		PE: "Peru",
		PH: "Philippines",
		PN: "Pitcairn",
		PL: "Poland",
		PF: "French Polynesia",
		PR: "Puerto Rico",
		PT: "Portugal",
		QA: "Qatar",
		HK: "Hong Kong",
		MO: "Macao",
		QO: "Remote Oceania",
		CF: "Central African Republic",
		CD: "Democratic Republic of the Congo",
		DO: "Dominican Republic",
		CZ: "Czech Republic",
		RE: "Reunion",
		RO: "Romania",
		GB: "United Kingdom",
		RU: "Russia",
		RW: "Rwanda",
		EH: "Western Sahara",
		BL: "Saint Barthélemy",
		KN: "Saint Kitts and Nevis",
		SM: "San Marino",
		MF: "Saint Martin Island",
		PM: "Saint Pierre and Miquelon",
		VC: "Saint Vincent and the Grenadines",
		SH: "Saint Helena",
		LC: "Saint Lucia",
		WS: "Samoa",
		AS: "American Samoa",
		ST: "São Tomé and Príncipe",
		SN: "Senegal",
		RS: "Serbia",
		CS: "Serbia and Montenegro",
		SC: "Seychelles",
		SL: "Sierra Leone",
		SG: "Singapore",
		SK: "Slovakia",
		SI: "Slovenia",
		SO: "Somalia",
		SD: "Sudan",
		LK: "Sri Lanka",
		SE: "Sweden",
		CH: "Switzerland",
		SR: "Suriname",
		SJ: "Svalbard and Jan Mayen",
		SZ: "Swaziland",
		SY: "Syria",
		TJ: "Tajikistan",
		TW: "Taiwan",
		TZ: "Tanzania",
		TD: "Chad",
		TF: "French Southern and Antarctic Lands",
		IO: "British Indian Ocean Territory",
		PS: "Palestinian territories",
		TH: "Thailand",
		TL: "East Timor",
		TG: "Togo",
		TK: "Tokelau",
		TO: "Tonga",
		TT: "Trinidad and Tobago",
		TA: "Tristan da Cunha",
		TN: "Tunisia",
		TM: "Turkmenistan",
		TR: "Turkey",
		TV: "Tuvalu",
		UA: "Ukraine",
		EU: "European Union",
		UY: "Uruguay",
		VU: "Vanuatu",
		VE: "Venezuela",
		VN: "Vietnam",
		WF: "Wallis and Futuna",
		YE: "Yemen",
		ZM: "Zambia",
		ZW: "Zimbabwe",
  },

  es: {
    AF: "Afganistán",
		ZA: "Sudáfrica",
		AL: "Albania",
		DZ: "Argelia",
		DE: "Alemania",
		AD: "Andorra",
		AO: "Angola",
		AI: "Anguila",
		AQ: "Antártida",
		AG: "Antigua y Barbuda",
		AN: "Antillas Neerlandesas",
		SA: "Arabia Saudita",
		AR: "Argentina",
		AM: "Armenia",
		AW: "Aruba",
		AU: "Australia",
		AT: "Austria",
		AZ: "Azerbaiyán",
		BS: "Bahamas",
		BH: "Baréin",
		BD: "Bangladés",
		BB: "Barbados",
		BY: "Belarús",
		BE: "Bélgica",
		BZ: "Belice",
		BJ: "Benín",
		BM: "Bermudas",
		BT: "Bután",
		BO: "Bolivia",
		BA: "Bosnia y Herzegovina",
		BW: "Botsuana",
		BR: "Brasil",
		BN: "Brunéi",
		BG: "Bulgaria",
		BF: "Burkina Faso",
		BI: "Burundi",
		KH: "Camboya",
		CM: "Camerún",
		CA: "Canadá",
		CV: "Cabo Verde",
		EA: "Ceuta y Melilla",
		CL: "Chile",
		CN: "China",
		CY: "Chipre",
		CO: "Colombia",
		KM: "Comoras",
		CG: "Congo-Brazzaville",
		KP: "Corea del Norte",
		KR: "Corea del Sur",
		CR: "Costa Rica",
		CI: "Costa de Marfil",
		HR: "Croacia",
		CU: "Cuba",
		DK: "Dinamarca",
		DG: "Diego García",
		DJ: "Yibuti",
		DM: "Dominica",
		EG: "Egipto",
		SV: "El Salvador",
		AE: "Emiratos Árabes Unidos",
		EC: "Ecuador",
		ER: "Eritrea",
		ES: "España",
		EE: "Estonia",
		VA: "Estado de la Ciudad del Vaticano",
		FM: "Estados Federados de Micronesia",
		US: "Estados Unidos",
		ET: "Etiopia",
		FJ: "Fiyi",
		FI: "Finlandia",
		FR: "Francia",
		GA: "Gabón",
		GM: "Gambia",
		GE: "Georgia",
		GS: "Islas Georgias del Sur y Sándwich del Sur",
		GH: "Ghana",
		GI: "Gibraltar",
		GR: "Grecia",
		GD: "Granada",
		GL: "Groenlandia",
		GP: "Guadalupe",
		GU: "Guam",
		GT: "Guatemala",
		GG: "Guernsey",
		GN: "Guinea",
		GQ: "Guinea ecuatorial",
		GW: "Guinea-Bisáu",
		GY: "Guyana",
		GF: "Guayana Francesa",
		HT: "Haití",
		HN: "Honduras",
		HU: "Hungría",
		BV: "Isla Bouvet",
		CX: "Isla de Navidad",
		CP: "Isla Clipperton",
		AC: "Isla Ascensión",
		IM: "Isla de Man",
		NF: "Isla Norfolk",
		AX: "Islas Åland",
		KY: "Islas Caimán",
		IC: "Islas Canarias",
		CC: "Islas Cocos / Islas Keeling",
		CK: "Islas Cook",
		FO: "Islas Feroe",
		HM: "Islas Heard y McDonald",
		FK: "Islas Malvinas",
		MP: "Islas Marianas del Norte",
		MH: "República de las Islas Marshall",
		UM: "Islas Ultramarinas Menores de los Estados Unidos",
		SB: "Islas Salomón",
		TC: "Islas Turcas y Caicos",
		VG: "Islas Vírgenes Británicas",
		VI: "Islas Vírgenes de os Estados Unidos",
		IN: "India",
		ID: "Indonesia",
		IQ: "Irak",
		IR: "Irán",
		IE: "Irlanda",
		IS: "Islandia",
		IL: "Israel",
		IT: "Italia",
		JM: "Jamaica",
		JP: "Japón",
		JE: "Jersey",
		JO: "Jordania",
		KZ: "Kazajistán",
		KE: "Kenia",
		KG: "Kirguistán",
		KI: "Kiribati",
		KW: "Kuwait",
		LA: "Laos",
		LS: "Lesoto",
		LV: "Letonia",
		LB: "Líbano",
		LR: "Liberia",
		LY: "Libia",
		LI: "Liechtenstein",
		LT: "Lituania",
		LU: "Luxemburgo",
		MK: "Macedonia",
		MG: "Madagascar",
		MY: "Malasia",
		MW: "Malaui",
		MV: "Maldivas",
		ML: "Malí",
		MT: "Malta",
		MA: "Marruecos",
		MQ: "Martinica",
		MU: "Mauricio",
		MR: "Mauritania",
		YT: "Mayotte",
		MX: "México",
		MD: "Moldavia",
		MC: "Mónaco",
		MN: "Mongolia",
		ME: "Montenegro",
		MS: "Montserrat",
		MZ: "Mozambique",
		MM: "Myanmar",
		NA: "Namibia",
		NR: "Nauru",
		NP: "Nepal",
		NI: "Nicaragua",
		NE: "Níger",
		NG: "Nigeria",
		NU: "Niue",
		NO: "Noruega",
		NC: "Nueva Caledonia",
		NZ: "Nueva Zelandia",
		OM: "Omán",
		UG: "Uganda",
		UZ: "Uzbekistán",
		PK: "Pakistán",
		PW: "Palaos",
		PA: "Panamá",
		PG: "Papúa Nueva Guinea",
		PY: "Paraguay",
		NL: "Países Bajos",
		PE: "Perú",
		PH: "Filipinas",
		PN: "Islas Pitcairn",
		PL: "Polonia",
		PF: "Polinesia Francesa",
		PR: "Puerto Rico",
		PT: "Portugal",
		QA: "Catar",
		HK: "R.A.E de Hong Kong de la República Popular China",
		MO: "R.A.E. de Macao",
		QO: "Oceanía Lejana",
		CF: "República Centroafricana",
		CD: "República Democrática del Congo",
		DO: "República Dominicana",
		CZ: "República Checa",
		RE: "Reunión",
		RO: "Rumanía",
		GB: "Reino Unido",
		RU: "Rusia",
		RW: "Ruanda",
		EH: "Sahara Occidental",
		BL: "San Bartolomé",
		KN: "San Cristóbal y Nieves",
		SM: "San Marino",
		MF: "Isla San Martín",
		PM: "San Pedro y Miquelón",
		VC: "San Vicente y las Granadinas",
		SH: "Isla Santa Elena",
		LC: "Santa Lucia",
		WS: "Samoa",
		AS: "Samoa Americana",
		ST: "Santo Tomé y Príncipe",
		SN: "Senegal",
		RS: "Serbia",
		CS: "Serbia y Montenegro",
		SC: "Seychelles",
		SL: "Sierra Leona",
		SG: "Singapur",
		SK: "Eslovaquia",
		SI: "Eslovenia",
		SO: "Somalia",
		SD: "Sudán",
		LK: "Sri Lanka",
		SE: "Suecia",
		CH: "Suiza",
		SR: "Surinam",
		SJ: "Svalbard y Jan Mayen",
		SZ: "Suazilandia",
		SY: "Siria",
		TJ: "Tayikistán",
		TW: "Taiwán",
		TZ: "Tanzania",
		TD: "Chad",
		TF: "Tierras Australes y Antárticas Francesas",
		IO: "Territorio Británico del Océano Índico",
		PS: "Territorios Palestinos",
		TH: "Tailandia",
		TL: "Timor Oriental",
		TG: "Togo",
		TK: "Tokelau",
		TO: "Tonga",
		TT: "Trinidad y Tobago",
		TA: "Tristán da Cunha",
		TN: "Túnez",
		TM: "Turkmenistán",
		TR: "Turquía",
		TV: "Tuvalu",
		UA: "Ucrania",
		EU: "Unión Europea",
		UY: "Uruguay",
		VU: "Vanuatu",
		VE: "Venezuela",
		VN: "Vietnam",
		WF: "Wallis y Futuna",
		YE: "Yemen",
		ZM: "Zambia",
		ZW: "Zimbabue",
  },
  pt: {
    AF: "Afeganistão",
		ZA: "África do Sul",
		AL: "Albânia",
		DZ: "Argélia",
		DE: "Alemanha",
		AD: "Andorra",
		AO: "Angola",
		AI: "Anguilla",
		AQ: "Antartica",
		AG: "Antiga e Barbuda",
		AN: "Antilhas Neerlandesas",
		SA: "Arábia Saudita",
		AR: "Argentina",
		AM: "Arménia",
		AW: "Aruba",
		AU: "Austrália",
		AT: "Áustria",
		AZ: "Azerbaijão",
		BS: "Bahamas",
		BH: "Barém",
		BD: "Bangladexe",
		BB: "Barbados",
		BY: "Bielorrússia",
		BE: "Bélgica",
		BZ: "Belize",
		BJ: "Benim",
		BM: "Bermudas",
		BT: "Butão",
		BO: "Bolívia",
		BA: "Bósnia e Herzegovina",
		BW: "Botsuana",
		BR: "Brasil",
		BN: "Brunei",
		BG: "Bulagária",
		BF: "Burquina Faso",
		BI: "Burúndi",
		KH: "Camboja",
		CM: "Camarões",
		CA: "Canadá",
		CV: "Cabo Verde",
		EA: "Ceuta e Melilla",
		CL: "Chile",
		CN: "China",
		CY: "Chipre",
		CO: "Colômbia",
		KM: "Comores",
		CG: "Congo-Brazzaville",
		KP: "Coreia do Norte",
		KR: "Coreia do Sul",
		CR: "Costa Rica",
		CI: "Costa do Marfim",
		HR: "Croácia",
		CU: "Cuba",
		DK: "Dinamarca",
		DG: "Diego Garcia",
		DJ: "Jibuti",
		DM: "Dominica",
		EG: "Egito",
		SV: "El Salvador",
		AE: "Emirados Árabes Unidos",
		EC: "Equador",
		ER: "Eritreia",
		ES: "Espanha",
		EE: "Estónia",
		VA: "Estado da Cidade do Vaticano",
		FM: "Estados Federados da Micronésia",
		US: "Estados Unidos",
		ET: "Etiópia",
		FJ: "Fiji",
		FI: "Finlândia",
		FR: "França",
		GA: "Gabão",
		GM: "Gâmbia",
		GE: "Geórgia",
		GS: "Ilhas Geórgia do Sul e Sandwich do Sul",
		GH: "Gana",
		GI: "Gibraltar",
		GR: "Grécia",
		GD: "Granada",
		GL: "Gronelândia",
		GP: "Guadalupe",
		GU: "Guam",
		GT: "Guatemala",
		GG: "Guernsey",
		GN: "Guiné",
		GQ: "Guiné Equatorial",
		GW: "Guiné-Bissau",
		GY: "Guiana",
		GF: "Guiana Francesa",
		HT: "Haiti",
		HN: "Honduras",
		HU: "Hungria",
		BV: "Ilha Bouvet",
		CX: "Ilha Christmas",
		CP: "Ilha Clipperton",
		AC: "Ilha de Ascensão",
		IM: "Ilha de Man",
		NF: "Ilha Norfolk",
		AX: "Ilhas de Aland",
		KY: "Ilhas Caimão",
		IC: "Ilhas Canárias",
		CC: "Ilhas Cocos (Keeling)",
		CK: "Ilhas Cook",
		FO: "Ilhas Feroe",
		HM: "Ilha Heard e Ilhas McDonald",
		FK: "Ilhas Malvinas",
		MP: "Ilhas Marianas Setentrionais",
		MH: "Ilhas Marechal",
		UM: "Ilhas Menores Distantes dos Estados Unidos",
		SB: "Ilhas Salomão",
		TC: "Ilhas Turcas e Caicos",
		VG: "Ilhas Virgens Britânicas",
		VI: "Ilhas Virgens Americanas",
		IN: "India",
		ID: "Indonésia",
		IQ: "Iraque",
		IR: "Irão",
		IE: "Irlanda",
		IS: "Islândia",
		IL: "Israel",
		IT: "Itália",
		JM: "Jamaica",
		JP: "Japão",
		JE: "Jersey",
		JO: "Jordânia",
		KZ: "Cazaquistão",
		KE: "Quénia",
		KG: "Quirguistão",
		KI: "Kiribati",
		KW: "Cuaite",
		LA: "Laus",
		LS: "Lesoto",
		LV: "Letónia",
		LB: "Líbano",
		LR: "Libéria",
		LY: "Líbia",
		LI: "Listenstaine",
		LT: "Lituânia",
		LU: "Luxemburgo",
		MK: "Macedónia",
		MG: "Madagáscar",
		MY: "Malásia",
		MW: "Maláui",
		MV: "Maldivas",
		ML: "Mali",
		MT: "Malta",
		MA: "Marrocos",
		MQ: "Martinica",
		MU: "Maurícia",
		MR: "Mauritânia",
		YT: "Maiote",
		MX: "México",
		MD: "Moldávia",
		MC: "Mónaco",
		MN: "Mongólia",
		ME: "Montenegro",
		MS: "Montserrat",
		MZ: "Moçambique",
		MM: "Mianmar",
		NA: "Namíbia",
		NR: "Nauru",
		NP: "Nepal",
		NI: "Nicarágua",
		NE: "Níger",
		NG: "Nigéria",
		NU: "Niue",
		NO: "Noruega",
		NC: "Nova Caledónia",
		NZ: "Nova Zelândia",
		OM: "Omã",
		UG: "Uganda",
		UZ: "Uzbequistão",
		PK: "Paquistão",
		PW: "Palau",
		PA: "Panamá",
		PG: "Papua Nova Guiné",
		PY: "Paraguai",
		NL: "Países Baixos",
		PE: "Peru",
		PH: "Filipinas",
		PN: "Ilhas Pitcairn",
		PL: "Polónia",
		PF: "Polinésia Francesa",
		PR: "Porto Rico",
		PT: "Portugal",
		QA: "Catar",
		HK: "R.A.E. de Hong Kong da República Popular da China",
		MO: "R.A.E. de Macau da República Popular da China",
		QO: "Ocenia Remoto",
		CF: "República Centro-Africana",
		CD: "República Democrática do Congo",
		DO: "República Dominicana",
		CZ: "República Checa",
		RE: "Reunião",
		RO: "Roménia",
		GB: "Reino Unido",
		RU: "Rússia",
		RW: "Ruanda",
		EH: "Saara Ocidental",
		BL: "São Bartolomeu",
		KN: "São Cristóvão e Neves",
		SM: "São Marinho",
		MF: "Ilha de São Martinho",
		PM: "São Pedro e Miquelão",
		VC: "São Vicente e Granadinas",
		SH: "Ilha Santa Helena",
		LC: "Santa Lúcia",
		WS: "Samoa Wúcia",
		AS: "Samoa Americana",
		ST: "São Tomé e Príncipe",
		SN: "Senegal",
		RS: "Sérvia",
		CS: "Sérvia e Montenegro",
		SC: "Seicheles",
		SL: "Serra Leoa",
		SG: "Singapura",
		SK: "Eslóvaquia",
		SI: "Eslóvenia",
		SO: "Somália",
		SD: "Sudã",
		LK: "Sri Lanca",
		SE: "Suécia",
		CH: "Suíça",
		SR: "Suriname",
		SJ: "Svalbard e Jan Mayen",
		SZ: "Suazilândia",
		SY: "Síria",
		TJ: "Tajiquistão",
		TW: "Taiwan",
		TZ: "Tanzânia",
		TD: "Chade",
		TF: "Terras Austrais e Antárticas Francesas",
		IO: "Território Britânico do Oceano Índico",
		PS: "Territórios palestinianos",
		TH: "Tailândia",
		TL: "Timor-Leste",
		TG: "Togo",
		TK: "Tokelau",
		TO: "Tonga",
		TT: "Trinidade e Tobago",
		TA: "Tristão da Cunha",
		TN: "Tunísia",
		TM: "Turcomenistão",
		TR: "Turquia",
		TV: "Tuvalu",
		UA: "Ucrânia",
		EU: "União Europeia",
		UY: "Uruguai",
		VU: "Vanuatu",
		VE: "Venezuela",
		VN: "Vietname",
		WF: "Wallis e Futuna",
		YE: "Iemen",
		ZM: "Zâmbia",
		ZW: "Zimbábue",
  }
};


