var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
import { Template } from '../../../../../../common/src/bdd/documents/Template';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Language } from '../../../../../../common/src/bdd/utility/Language';
import { Countries } from '../../../../../../common/src/bdd/utility/countries';
import { PDFParser } from '../../../../../../common/src/components/pdfpreview/pdfparser';
export function haveValidCountryValidator(id) {
    return function (control) {
        var noddress = false;
        if (control.parent) {
            var par = control.parent;
            var country = par.get(id).value;
            noddress = ((!country || !country.value));
        }
        return (noddress) ? { 'validCountry': true } : null;
    };
}
export function haveValidLanguageValidator(id) {
    return function (control) {
        var noddress = false;
        if (control.parent) {
            var par = control.parent;
            var language = par.get(id).value;
            var iso639 = null;
            if (language) {
                iso639 = Language.GetISO639(language);
            }
            noddress = ((!iso639));
        }
        return (noddress) ? { 'validLanguage': true } : null;
    };
}
var AddTemplateComponent = /** @class */ (function () {
    function AddTemplateComponent(hiero, fb, router, route) {
        var _this = this;
        this.hiero = hiero;
        this.fb = fb;
        this.router = router;
        this.route = route;
        this.busy = true;
        this.formatter = function (lang) { return lang ? lang : ''; };
        this.search = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) {
                return term.length < 2
                    ? []
                    : _this.fullLanguageList.filter(function (value) { return (value.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null); }, 10);
            }));
        };
        this.countryFormatter = function (country) { return country ? country.label : ''; };
        this.countrySearch = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) {
                return term.length < 2
                    ? []
                    : Countries.GetCountryArray('fr').filter(function (value) { return (value.label.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null); }, 10);
            }));
        };
        this.fullLanguageList = Language.GetAllNativeAndRegularNames();
        this.adminSub = this.hiero.WatchAdmin({
            next: function (admin) {
                _this.admin = admin;
                DocumentType.Init(admin.User, true)
                    .then(function (map) {
                    _this.docTypeMap = map;
                    _this.createForm();
                    _this.busy = false;
                });
            }
        });
        this.createForm();
    }
    AddTemplateComponent.prototype.ngOnDestroy = function () {
        if (this.adminSub) {
            this.adminSub.unsubscribe();
        }
    };
    AddTemplateComponent.prototype.getDocTypeFor = function (code, documentTypeId) {
        return this.docTypeMap.GetDocumentName(code, documentTypeId);
    };
    AddTemplateComponent.prototype.getCountryName = function (code) {
        return Countries.GetCountryMap('fr').get(code);
    };
    AddTemplateComponent.prototype.createForm = function () {
        var _this = this;
        this.newForm = new SubmitForm(this.fb, [
            {
                name: 'srcCode',
                value: '',
                validators: [haveValidLanguageValidator('srcCode')],
                type: 'text',
                title: 'Langue d\'origine',
                autocomplete: 'off',
                placeholder: 'Commencez à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validLanguage',
                        message: 'Merci de sélectionner la langue de la liste défilante !',
                    },
                ]
            },
            {
                name: 'srcCountry',
                value: '',
                validators: [haveValidCountryValidator('srcCountry')],
                type: 'text',
                title: 'Pays d\'origine',
                autocomplete: 'off',
                placeholder: 'Taper le nom du pays...',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validCountry',
                        message: 'Merci de sélectionner le pays de la liste défilante !',
                    },
                ]
            },
            {
                name: 'dstCode',
                value: '',
                validators: [haveValidLanguageValidator('dstCode')],
                type: 'text',
                title: 'Traduire vers...',
                autocomplete: 'off',
                placeholder: 'Commencez à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validLanguage',
                        message: 'Merci de sélectionner la langue de la liste défilante !',
                    },
                ]
            },
            {
                name: 'type',
                value: '',
                validators: [],
                type: 'text',
                title: 'Type de document',
                autocomplete: 'off',
                placeholder: '',
                help: 'Le type de document representé par ce document',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            },
            {
                name: 'price',
                value: '',
                validators: [Validators.required, Validators.min(0.1)],
                type: 'number',
                title: 'Prix HT',
                autocomplete: 'off',
                placeholder: '',
                min: 0.1,
                help: 'Le prix HT du document percu par le traducteur pour une prestation de ce type',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'min',
                        message: 'Minimum de 0.1€ obligatoire',
                    },
                ]
            }
        ], 
        // Submit callback
        function (changes) {
        }, 
        // Success callback
        function () {
            // What to do with login success ?
            _this.busy = false;
        }, 
        // Fail callback
        function (err) {
            // What to do with login failuer
            console.error(err);
            _this.busy = false;
        }, 
        // Changes callback
        null);
        this.setupDropdown();
    };
    AddTemplateComponent.prototype.setupDropdown = function () {
        var docTypes = [];
        if (this.docTypeMap) {
            this.docTypeMap.ToArray.forEach(function (docType) {
                var docName = docType.label.find(function (label) { return label.code === 'fr'; });
                if (docName) {
                    docTypes.push({
                        name: docName.value,
                        data: docType._id
                    });
                }
            });
        }
        if (docTypes.length > 0) {
            this.selectedDocType = docTypes[0].data;
        }
        this.documentTypeDropdown = {
            choices: docTypes,
            selectedIndex: 0,
            title: '',
            input_title: 'Type de document',
            hasButton: false,
            button_title: '',
            help: ''
        };
    };
    AddTemplateComponent.prototype.onCountrySelected = function (country) {
        console.log(country);
        /* const oldAddress = this.profileForm.GetValue('address');
        if (oldAddress && oldAddress.extra) {
          address.address.extra = oldAddress.extra;
        }
    
        this.profileForm.SetValue('coords', address.coords);
        this.profileForm.SetValue('address', address.address);*/
    };
    AddTemplateComponent.prototype.onSrcSelected = function (country) {
        console.log(country);
        /* const oldAddress = this.profileForm.GetValue('address');
        if (oldAddress && oldAddress.extra) {
          address.address.extra = oldAddress.extra;
        }
    
        this.profileForm.SetValue('coords', address.coords);
        this.profileForm.SetValue('address', address.address);*/
    };
    AddTemplateComponent.prototype.onDstSelected = function (country) {
        console.log(country);
        /* const oldAddress = this.profileForm.GetValue('address');
        if (oldAddress && oldAddress.extra) {
          address.address.extra = oldAddress.extra;
        }
    
        this.profileForm.SetValue('coords', address.coords);
        this.profileForm.SetValue('address', address.address);*/
    };
    AddTemplateComponent.prototype.selectDocumentType = function (data) {
        this.selectedDocType = data.data;
    };
    AddTemplateComponent.prototype.addTemplate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var src, dst, srcCountryData, price, srcCode, dstCode, srcCountry, priceNum, t, newTemplate, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        src = this.newForm.GetValue('srcCode');
                        dst = this.newForm.GetValue('dstCode');
                        srcCountryData = this.newForm.GetValue('srcCountry');
                        price = this.newForm.GetValue('price');
                        srcCode = Language.GetISO639(src);
                        dstCode = Language.GetISO639(dst);
                        srcCountry = srcCountryData.value;
                        /// VALIDIITY
                        if (!this.newForm.CheckValidity()) {
                            return [2 /*return*/];
                        }
                        if (!srcCode) {
                            this.newForm.SetGeneralError("Error: missing info: srcCode");
                        }
                        if (!dstCode) {
                            this.newForm.SetGeneralError("Error: missing info: dstCode");
                        }
                        if (!srcCountry) {
                            this.newForm.SetGeneralError("Error: missing info: srcCountry");
                        }
                        if (!this.selectedDocType) {
                            this.newForm.SetGeneralError("Error: missing info: this.selectedDocType");
                        }
                        if (!price) {
                            this.newForm.SetGeneralError("Error: missing info: price");
                        }
                        if (!this.pdfRender) {
                            this.newForm.SetGeneralError("Error: missing info: this.pdfRender");
                        }
                        if (!srcCode || !dstCode || !srcCountry || !this.selectedDocType || !price || !this.pdfRender) {
                            console.log(srcCode);
                            console.log(dstCode);
                            console.log(srcCountry);
                            console.log(this.selectedDocType);
                            console.log(price);
                            return [2 /*return*/];
                        }
                        this.busy = true;
                        this.pdfRender.clearHighlights();
                        priceNum = parseFloat(price);
                        t = {
                            srcLanguageIso639: srcCode,
                            srcCountryCode: srcCountry,
                            destLanguageIso639: dstCode,
                            documentTypeId: this.selectedDocType,
                            priceHT: priceNum,
                            config: this.pdfRender.Data
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Template.Create(this.admin.User, t)];
                    case 2:
                        newTemplate = _a.sent();
                        this.pdfRender = null;
                        this.router.navigate(['/app', 'template', newTemplate.Id]);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.newForm.SetGeneralError(err_1.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AddTemplateComponent.prototype.dropped = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var droppedFile, fileEntry, pdfRender, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (files.length === 0) {
                            return [2 /*return*/];
                        }
                        droppedFile = files[0];
                        if (!droppedFile.fileEntry.isFile) return [3 /*break*/, 4];
                        fileEntry = droppedFile.fileEntry;
                        this.busy = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, PDFParser.Parse(fileEntry)];
                    case 2:
                        pdfRender = _a.sent();
                        this.pdfRender = pdfRender;
                        this.busy = false;
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        console.warn(err_2.message);
                        this.busy = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AddTemplateComponent.prototype.fileOver = function (event) {
        console.log(event);
    };
    AddTemplateComponent.prototype.fileLeave = function (event) {
        console.log(event);
    };
    return AddTemplateComponent;
}());
export { AddTemplateComponent };
