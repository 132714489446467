var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Admin } from '../../../common/src/bdd/admin/Admin';
import { AuthService } from '../../../common/src/bdd/user/AuthService';
import { ERoleType } from '../../../common/src/bdd/user/User';
import { Config } from '../../../common/src/services/config.service';
import { GoogleGeo } from './google.services';
var HieroBDD = /** @class */ (function () {
    function HieroBDD(config, google) {
        var _this = this;
        this.config = config;
        this.google = google;
        // Initialize Firebase
        firebase.initializeApp(this.config.Environment.firebase);
        // Create the database object
        this._db = firebase.firestore();
        this._auth = firebase.auth();
        this._storage = firebase.storage();
        this._adminSubject = new BehaviorSubject(null);
        // Setup the service provider
        this._authService = new AuthService(this._auth, this._db, ERoleType.admin);
        // Setup extra login steps:
        // 1/ Login automatically as a translator
        this._authService.extraLoginSteps.push(function (user) { return __awaiter(_this, void 0, void 0, function () {
            var admin;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Admin.Init(user)];
                    case 1:
                        admin = _a.sent();
                        this._adminSubject.next(admin);
                        return [2 /*return*/];
                }
            });
        }); });
        // Set up a watch on user, if user becomes null, so does traducteur
        this._authService.WatchUser({
            next: function (user) {
                if (user == null) {
                    _this._adminSubject.next(null);
                }
            }
        });
        // Start listener
        this.Auth.Listen();
    }
    Object.defineProperty(HieroBDD.prototype, "Auth", {
        get: function () {
            return this._authService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HieroBDD.prototype, "DB", {
        get: function () {
            return this._db;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HieroBDD.prototype, "Storage", {
        get: function () {
            return this._storage;
        },
        enumerable: true,
        configurable: true
    });
    HieroBDD.prototype.WatchAdmin = function (observer) {
        return this._adminSubject.subscribe(observer);
    };
    HieroBDD.prototype.Admin = function () {
        return this._adminSubject.value;
    };
    return HieroBDD;
}());
export { HieroBDD };
