var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { User } from './User';
import * as yup from 'yup';
/**
 * Represents status options of the user login. This is not a binary option, as we may be in a 'waiting' state.
 */
export var ELoggedInStatus;
(function (ELoggedInStatus) {
    /**
     * Still waiting to determing if the user is logged in or not
     */
    ELoggedInStatus[ELoggedInStatus["Waiting"] = 0] = "Waiting";
    /**
     * User is logged in and authorized
     */
    ELoggedInStatus[ELoggedInStatus["LoggedIn"] = 1] = "LoggedIn";
    /**
     * User is not logged in
     */
    ELoggedInStatus[ELoggedInStatus["NotLoggedIn"] = 2] = "NotLoggedIn";
})(ELoggedInStatus || (ELoggedInStatus = {}));
/**
 * Represents the different signup types permitted on the platform
 */
export var ESignupType;
(function (ESignupType) {
    ESignupType["EmailPassword"] = "email-password";
})(ESignupType || (ESignupType = {}));
/**
 * Auth service is the starting point for the Hiero API library. A valid and initialised firebase instance must be passed to the
 * constructor, and this service then handles the rest.
 *
 * Auth service will automatically monitor changes to the state of the user, and fire events via subscriptions (the Watch* methods).
 */
var AuthService = /** @class */ (function () {
    function AuthService(auth, db, role) {
        // Additional login steps
        this.extraLoginSteps = [];
        // Just before
        this.justBeforeStateFireSteps = [];
        this._role = role;
        this._auth = auth;
        this._db = db;
        this._userSubject = new BehaviorSubject(null);
        this._userLoggedInSubject = new BehaviorSubject(ELoggedInStatus.Waiting); // Initial state is waiting
        this._stateListenerUnsubscribe = null;
        this._signupProfile = null;
        this._lastInitUid = null;
        this._initMap = new Map();
    }
    AuthService.prototype.Listen = function () {
        var _this = this;
        // Make sure we only have one listener ever!
        if (!this._stateListenerUnsubscribe) {
            // Can be called multiple times be careful!!
            this._stateListenerUnsubscribe = this._auth.onAuthStateChanged(function (user) {
                if (user) {
                    // Handle user state change
                    _this.handleStateChange(user);
                }
                else {
                    // Signout
                    _this.signalLoggedOut();
                }
            }, function (err) {
                // Error was encountered, signout        
                _this.signalLoggedOut();
            });
        }
    };
    AuthService.prototype.StopListening = function () {
        if (this._stateListenerUnsubscribe) {
            this._stateListenerUnsubscribe();
            this._stateListenerUnsubscribe = null;
        }
    };
    /**
     * Logs the current user out.
     */
    AuthService.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this._auth.signOut()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        console.log('Error while signing out. Ignoring.');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Watch for changes on the user. This will fire with the value null if the user is logged out.
     * The observer pass should be of the form:
     * ```
     * {
     *    next: (user: User) => { ... }
     * }
     * ```
     * @param observer The observer that will receive an update
     * @returns A subscription that can be cancelled by calling `unsubscribe()` on this object.
     */
    AuthService.prototype.WatchUser = function (observer) {
        return this._userSubject.subscribe(observer);
    };
    Object.defineProperty(AuthService.prototype, "User", {
        get: function () {
            return this._userSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Watch for changes on the login status. The user is considered logged in if a series of validation steps have been completed.
     * The observer pass should be of the form:
     * ```
     * {
     *    next: (status: ELoggedInStatus) => { ... }
     * }
     * ```
     * @param observer The observer that will receive an update
     * @returns A subscription that can be cancelled by calling `unsubscribe()` on this object.
     */
    AuthService.prototype.WatchLoggedInStatus = function (observer) {
        return this._userLoggedInSubject.subscribe(observer);
    };
    Object.defineProperty(AuthService.prototype, "LoggedInStatus", {
        /**
         * Get the current logged in status of the user.
         * @returns The current logged in status.
         */
        get: function () {
            return this._userLoggedInSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    /***
     * Logs in the user as a specific role.
     * Note, this function will start by logging out the user (just in case), to ensure startig from a clean slate.
     * The promise will eventually return true when the use is logged in, or reject if the login failed.
     * @param signupProfile If a profile is passed, this profile will be used to create a new profile for the user.
     */
    AuthService.prototype.login = function (loginData, signupProfile) {
        return __awaiter(this, void 0, void 0, function () {
            var sub;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._signupProfile = signupProfile;
                        // Do a logout
                        return [4 /*yield*/, this.logout()];
                    case 1:
                        // Do a logout
                        _a.sent();
                        this._userLoggedInSubject.next(ELoggedInStatus.Waiting);
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                // Set up a subscription to wait for a change in status
                                sub = _this.WatchLoggedInStatus({
                                    next: function (status) {
                                        if (sub) {
                                            sub.unsubscribe();
                                        }
                                        if (status === ELoggedInStatus.LoggedIn) {
                                            return resolve(true);
                                        }
                                        else if (status === ELoggedInStatus.NotLoggedIn) {
                                            return reject(false);
                                        }
                                    }
                                });
                                switch (loginData.type) {
                                    case ESignupType.EmailPassword:
                                        var schema = yup.object({
                                            email: yup.string().ensure().trim().lowercase(),
                                            password: yup.string().ensure().trim(),
                                        });
                                        schema.validate(loginData)
                                            .then(function (validated) {
                                            return _this._auth.signInWithEmailAndPassword(validated.email, validated.password);
                                        })
                                            .catch(function (err) {
                                            console.log(err);
                                            reject(err);
                                        });
                                        break;
                                    default:
                                        reject('Unknown login type.');
                                        break;
                                }
                            })];
                }
            });
        });
    };
    /***
     * Sign's a new user for a specified role.
     * If the user already exists, the signup will fail.
     * @param signupData The data needed to sign the user up
     * @param signupProfile Profile information necessary for the signup.
     * @param verifyData The options for sending the verify email
     */
    AuthService.prototype.signup = function (signupData, signupProfile, verifyData) {
        return __awaiter(this, void 0, void 0, function () {
            var sub;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._signupProfile = signupProfile;
                        // Do a logout
                        return [4 /*yield*/, this.logout()];
                    case 1:
                        // Do a logout
                        _a.sent();
                        // Notify that we are going into a waiting state
                        this._userLoggedInSubject.next(ELoggedInStatus.Waiting);
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                // Set up a subscription to wait for a change in status
                                sub = _this.WatchLoggedInStatus({
                                    next: function (status) {
                                        if (sub) {
                                            sub.unsubscribe();
                                        }
                                        if (status === ELoggedInStatus.LoggedIn) {
                                            return resolve(true);
                                        }
                                        else if (status === ELoggedInStatus.NotLoggedIn) {
                                            return reject(false);
                                        }
                                    }
                                });
                                switch (signupData.type) {
                                    case ESignupType.EmailPassword:
                                        // Create account
                                        var schema = yup.object({
                                            email: yup.string().ensure().trim().lowercase(),
                                            password: yup.string().ensure().trim(),
                                        });
                                        schema.validate(signupData)
                                            .then(function (validated) {
                                            return _this._auth.createUserWithEmailAndPassword(validated.email, validated.password);
                                        })
                                            // TODO: Do we need email verification ??
                                            /* .then(
                                              (cred: firebase.auth.UserCredential) => {
                                                console.log("SIGNUP OK... SENDING EMAIL");
                                                return cred.user.sendEmailVerification(
                                                  {
                                                    url:  'https://hiero.page.link/verify',
                                                    android: {
                                                      packageName: 'tech.kevinglass.hiero',
                                                    },
                                                    iOS: {
                                                      bundleId: 'tech.kevinglass.hiero',
                                                    },
                                                    handleCodeInApp: false
                                                  }
                                                );
                                              }
                                            )*/
                                            .then(function () {
                                            resolve(true);
                                        })
                                            .catch(function (err) {
                                            reject(err);
                                        });
                                        break;
                                    default:
                                        return Promise.reject('Unknown signup type.');
                                }
                            })];
                }
            });
        });
    };
    /***
     * Logs in the user as a specific role.
     * Note, this function will start by logging out the user (just in case), to ensure startig from a clean slate.
     * The promise will eventually return true when the use is logged in, or reject if the login failed.
     * @param signupProfile If a profile is passed, this profile will be used to create a new profile for the user.
     */
    AuthService.prototype.sendPasswordReset = function (email, resetLink) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var resetData = {
                            email: email
                        };
                        var schema = yup.object({
                            email: yup.string().ensure().trim().lowercase(),
                        });
                        schema.validate(resetData)
                            .then(function (validated) {
                            //console.log("Sending password reset to: " + validated.email);
                            return _this._auth.sendPasswordResetEmail(validated.email, {
                                url: resetLink,
                                android: {
                                    packageName: 'com.bymeunivers.hieromobile',
                                },
                                iOS: {
                                    bundleId: 'com.bymeunivers.hieromobile',
                                },
                                handleCodeInApp: false
                            });
                        })
                            .then(function () {
                            resolve(true);
                        })
                            .catch(function (err) {
                            console.log(err);
                            reject(err);
                        });
                    })];
            });
        });
    };
    AuthService.prototype.removeFromInitMap = function (uid) {
        // Remove from the map
        if (this._initMap.has(uid)) {
            this._initMap.delete(uid);
        }
    };
    AuthService.prototype.setupUser = function (fbUser) {
        return __awaiter(this, void 0, void 0, function () {
            var loggedUser, i, i, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 12, , 14]);
                        return [4 /*yield*/, User.Init(fbUser, this._db, this._role, this._signupProfile)];
                    case 1:
                        loggedUser = _a.sent();
                        if (!loggedUser) {
                            this.removeFromInitMap(fbUser.uid);
                            return [2 /*return*/, null];
                        }
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.extraLoginSteps.length)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.extraLoginSteps[i](loggedUser)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        ++i;
                        return [3 /*break*/, 2];
                    case 5:
                        // Remove from the map
                        this.removeFromInitMap(fbUser.uid);
                        if (!(this._lastInitUid === fbUser.uid)) return [3 /*break*/, 10];
                        // Activate the user event
                        this._userSubject.next(loggedUser);
                        i = 0;
                        _a.label = 6;
                    case 6:
                        if (!(i < this.justBeforeStateFireSteps.length)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.justBeforeStateFireSteps[i](loggedUser)];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8:
                        ++i;
                        return [3 /*break*/, 6];
                    case 9:
                        // Activate the logged in status
                        this._userLoggedInSubject.next(ELoggedInStatus.LoggedIn);
                        return [2 /*return*/, loggedUser];
                    case 10: 
                    // OOPS: this user is an old one, ignore it
                    return [2 /*return*/, null];
                    case 11: return [3 /*break*/, 14];
                    case 12:
                        err_2 = _a.sent();
                        // If an error was caught, the user should log out
                        // This should automatically end up firing the log-out below
                        console.warn('Error encountered while logging in: ' + err_2);
                        console.warn('Signing out');
                        this.removeFromInitMap(fbUser.uid);
                        return [4 /*yield*/, this.logout()];
                    case 13:
                        _a.sent();
                        return [2 /*return*/, null];
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.handleStateChange = function (fbUser) {
        // Most recent state change... last come is the one served.
        this._lastInitUid = fbUser.uid;
        if (this._initMap.has(fbUser.uid)) {
            // Already have an init running for this user... do nothing
        }
        else {
            this._initMap.set(fbUser.uid, this.setupUser(fbUser));
        }
    };
    AuthService.prototype.signalLoggedOut = function () {
        // User is logged out
        this._signupProfile = null;
        // Activate the user event
        this._userSubject.next(null);
        // Activate the logged in status
        this._userLoggedInSubject.next(ELoggedInStatus.NotLoggedIn);
    };
    return AuthService;
}());
export { AuthService };
