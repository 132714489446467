import { APP_DOMAIN, APP_SUBPATH, GOOGLE_GEOCODE_API_KEY } from '../../common/environment/config.stage';
import { IConfig, LoggingLevel } from '../../common/src/interfaces/config/IConfig';

export const environment: IConfig = {
  firebase: {
    apiKey: 'AIzaSyBziOT6-VY_ijdZ1tQLcfH3t5WwzySRYLE',
    authDomain: 'hiero-stage.firebaseapp.com',
    databaseURL: 'https://hiero-stage.firebaseio.com',
    projectId: 'hiero-stage',
    storageBucket: 'hiero-stage.appspot.com',
    messagingSenderId: '155722571964',
    appId: '1:155722571964:web:6088c1a58561598c98b0b9'
  },
  app: {
    origin: APP_DOMAIN,
    subpath: APP_SUBPATH
  },
  appBundleId: 'tech.kevinglass.hiero',
  appMinimumVersion: '0',
  production: true,
  logLevel: LoggingLevel.Warning,
  google_geocode_apikey: GOOGLE_GEOCODE_API_KEY
};
