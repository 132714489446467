<div id="page-container" class="page-sidebar-fixed page-header-fixed">
  <div id="header" class="header navbar-default">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-white justify-content-between"
    >
      <a [routerLink]="['home']" class="navbar-brand">
        <img src="../assets/img/logo/bird.png" height="80px" /> Hierø - <font size="3"> Plateforme admin </font>
      </a>

      <div dropdown class="nav-item dropdown navbar-user">
        <a
          href="javascript:;"
          dropdownToggle
          class="nav-link dropdown-toggle"
          data-toggle="dropdown"
        >
          <img src="../assets/img/user/user-icon.png" alt="" />
          <span class="d-none d-md-inline"
            >{{ profile.givenName }} {{ profile.familyName }}</span
          >
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <!--
            <a [routerLink]="['traducteur']" class="dropdown-item">Éditer mon profil</a>
            <a href="javascript:;" class="dropdown-item">Règles de confidentialité & Conditions d'utilisation</a>
            <div class="dropdown-divider"></div>
          -->
          <a href="javascript:;" (click)="logout()" class="dropdown-item"
            >Se déconnecter</a
          >
        </div>
      </div>
    </nav>
  </div>

  <!--  --- --- --- -->

  <!--
    <div class="navbar-header">
      <a [routerLink]="['home']" class="navbar-brand"><img src="../assets/img/logo/bird.png" height="80px">   Hierø - <font size="3"> Plateforme traducteur </font></a>
      <button type="button" class="navbar-toggle" data-click="sidebar-toggled">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>

    <ul class="navbar-nav navbar-right">


      <li class="dropdown">
        <a href="javascript:;" data-toggle="dropdown" class="dropdown-toggle f-s-14">
          <i class="fa fa-bell"></i>
          <span class="label"></span>
        </a>
        <ul class="dropdown-menu media-list dropdown-menu-right">
          <li class="dropdown-header">NOTIFICATIONS</li>
          <li class="media">
            <a href="javascript:;">
              <div class="media-left">
                <i class="fa fa-plus media-object bg-silver-darker"></i>
              </div>
              <div class="media-body">
                <h6 class="media-heading"> Hierø</h6>
                <div class="text-muted f-s-11">version 1.0</div>
              </div>
            </a>
          </li>

        </ul>
      </li>
      
      <li dropdown class="dropdown navbar-user">
        <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown">
          <img src="../assets/img/user/user-icon.png" alt="" /> 
          <span class="d-none d-md-inline">{{profile.givenName}} {{profile.familyName}}</span> <b class="caret"></b>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <a [routerLink]="['traducteur']" class="dropdown-item">Éditer mon profil</a>
          <a href="javascript:;" class="dropdown-item">Règles de confidentialité & Conditions d'utilisation</a>
          <div class="dropdown-divider"></div>
          <a (click)="logout()" class="dropdown-item">Se déconnecter</a>
        </div>
      </li>
    </ul>
    -->

  <!-- begin #sidebar -->
  <div id="sidebar" class="sidebar">
    <!-- begin sidebar scrollbar -->
    <div data-scrollbar="true" data-height="100%">
      <!-- begin sidebar user -->
      <ul class="nav">
        <li class="nav-profile">
          <!--<a href="javascript:;" data-toggle="nav-profile">-->
          <div class="cover with-shadow"></div>
          <div class="image">
            <img src="../assets/img/user/user-icon-inverted.png" alt="" />
          </div>
          <div class="info">            
          </div>
          <!--</a>-->
        </li>
        <!--
          <li>
          <ul class="nav nav-profile">
            <li><a (click)="logout()"><i class="fa fa-cog"></i> Se déconnecter </a></li>
            <li><a href="javascript:;"><i class="fa fa-question-circle"></i> Aide</a></li>
          </ul>
        </li>
        -->
      </ul>
      <!-- end sidebar user -->
      <!-- begin sidebar nav -->
      <ul class="nav">
        <li class="nav-header">Navigation</li>

        <li [routerLink]="['documentTypes']" [routerLinkActive]="['active']" class="clickable shadow-none"><a><i class="fa fa-plus"></i> <span>Ajouter document</span></a></li>
        <li [routerLink]="['templates']" [routerLinkActive]="['active']" class="clickable shadow-none"><a><i class="fa fa-edit"></i> <span>Éditer template</span></a></li>
       
      </ul>
      <!-- end sidebar nav -->
    </div>
    <!-- end sidebar scrollbar -->
  </div>
  <div class="sidebar-bg"></div>
  <!-- end #sidebar -->

  <!-- begin #content -->
  <div id="content" class="content content-full-width">
    <!-- begin profile -->
    <!--
      <div class="profile">
        <div class="profile-header">
          <div class="profile-header-cover"></div>
          <div class="profile-header-content">
            <div class="profile-header-img">
              <img src="../assets/img/user/user-image.png" alt="">
            </div>
            <div class="profile-header-info">
              <h4 class="m-t-10 m-b-5">{{profile.givenName}} {{profile.familyName}}</h4>

              <p class="m-b-10">Traducteur assermenté</p>
              <a [routerLink]="['profile']" class="btn btn-xs btn-yellow"> &#9998; Éditer mon profil</a>
            </div>
          </div>
          <ul class="profile-header-tab nav nav-tabs">
            <li class="nav-item"><a class="nav-link" data-toggle="tab"></a></li>
          </ul>
        </div>
      </div>
      -->
    <div class="profile">
      <div class="profile-header">
        <div class="profile-header-cover"></div>
        <div class="profile-header-content">
          {{ getTitle() }}
        </div>
      </div>
    </div>

    <div class="container-fluid pl-4 pt-4">
      <router-outlet></router-outlet>
    </div>
  </div>
  <notifier-container></notifier-container>
</div>
