var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EPDFRotation } from '../../bdd/interfaces/IPDF';
import { PDFRender } from './pdfrender';
var pdfjsLib = window['pdfjs-dist/build/pdf'];
var PDFParser = /** @class */ (function () {
    function PDFParser() {
    }
    PDFParser.Parse = function (fileEntry) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (!fileEntry.file) {
                    return [2 /*return*/, Promise.reject('File must not be null')];
                }
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        // console.log(this.fileEntry);
                        fileEntry.file(function (fileData) {
                            var fileReader = new FileReader();
                            fileReader.onload = function () {
                                // Step 4:turn array buffer into typed array
                                var typedarray = new Uint8Array(fileReader.result);
                                resolve(typedarray);
                            };
                            fileReader.onerror = function () {
                                fileReader.abort();
                                return reject(Error('Error reading pdf file'));
                            };
                            // Step 3: Read the file as ArrayBuffer
                            fileReader.readAsArrayBuffer(fileData);
                        });
                    })
                        .then(function (typedarray) {
                        // Step 5:PDFJS should be able to read this
                        var task = pdfjsLib.getDocument(typedarray);
                        return task.promise;
                    })
                        .then(function (pdf) {
                        return _this.processPdf(pdf);
                    })
                        .then(function (pdf) {
                        var pdfRender = new PDFRender(pdf);
                        return pdfRender;
                    })];
            });
        });
    };
    PDFParser.processPdf = function (pdf) {
        return __awaiter(this, void 0, void 0, function () {
            var pdfDef, promises, _loop_1, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pdfDef = {
                            pageCount: pdf.numPages,
                            pages: []
                        };
                        promises = [];
                        _loop_1 = function (i) {
                            var pageDef = {
                                valid: false,
                                width: 0,
                                height: 0,
                                orientation: EPDFRotation.Portrait,
                                inputs: []
                            };
                            pdfDef.pages.push(pageDef);
                            promises.push(pdf.getPage(i)
                                .then(function (page) {
                                console.log(page);
                                pageDef.valid = true;
                                if ((page.rotate % 360) === 0) {
                                    pageDef.width = page.view[2];
                                    pageDef.height = page.view[3];
                                    pageDef.orientation = EPDFRotation.Portrait;
                                }
                                else if ((page.rotate % 360) === 90) {
                                    pageDef.width = page.view[3];
                                    pageDef.height = page.view[2];
                                    pageDef.orientation = EPDFRotation.LandscapeRight;
                                }
                                else if ((page.rotate % 360) === -90 || (page.rotate % 360) === 270) {
                                    pageDef.width = page.view[3];
                                    pageDef.height = page.view[2];
                                    pageDef.orientation = EPDFRotation.LandscapeLeft;
                                }
                                else {
                                    pageDef.width = page.view[2];
                                    pageDef.height = page.view[3];
                                    pageDef.orientation = EPDFRotation.PortaitInvert;
                                }
                                return page.getTextContent()
                                    .then(function (textContent) {
                                    return page.getAnnotations();
                                })
                                    .then(function (annotations) {
                                    var id = 0;
                                    annotations.forEach(function (annotation) {
                                        if (annotation.fieldType === 'Tx') {
                                            var annot = PDFParser.parseTextBoxAnnotation(annotation, pageDef);
                                            annot.id = id;
                                            id++;
                                            pageDef.inputs.push(annot);
                                        }
                                    });
                                });
                            }));
                        };
                        for (i = 1; i <= pdfDef.pageCount; ++i) {
                            _loop_1(i);
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, pdfDef];
                }
            });
        });
    };
    PDFParser.parseTextBoxAnnotation = function (annotation, pageDef) {
        console.log(annotation);
        // RECT:
        // 0 = left side of rect in pts from left margin
        // 1 = bottom side of rect in pts from bottom margin
        // 2 = right side of rect in pts from left margin
        // 3 = top side of rect in pts from bottom margin
        var align = 'left';
        var x = 0;
        var y = 0;
        var width = 0;
        var height = 0;
        switch (pageDef.orientation) {
            case EPDFRotation.Portrait:
                x = annotation.rect[0];
                y = pageDef.height - annotation.rect[3];
                width = annotation.rect[2] - annotation.rect[0];
                height = annotation.rect[3] - annotation.rect[1];
                break;
            case EPDFRotation.LandscapeRight:
                x = annotation.rect[1]; // bottom
                y = annotation.rect[0]; // left
                width = annotation.rect[3] - annotation.rect[1];
                height = annotation.rect[2] - annotation.rect[0];
                break;
            case EPDFRotation.LandscapeLeft:
                x = pageDef.width - annotation.rect[3];
                y = pageDef.height - annotation.rect[2];
                width = annotation.rect[3] - annotation.rect[1];
                height = annotation.rect[2] - annotation.rect[0];
                break;
            case EPDFRotation.PortaitInvert:
                x = pageDef.width - annotation.rect[2];
                y = annotation.rect[1];
                width = annotation.rect[2] - annotation.rect[0];
                height = annotation.rect[3] - annotation.rect[1];
                break;
        }
        var rectX = x;
        var rectY = y;
        if (annotation.textAlignment) {
            switch (annotation.textAlignment) {
                case 1:
                    align = 'center';
                    x = x + (width * 0.5);
                    break;
                case 2:
                    align = 'right';
                    x = x + width;
                    break;
            }
        }
        var bold = false;
        var italic = false;
        var fontSize = 10;
        if (annotation.defaultAppearance) {
            var texts = annotation.defaultAppearance.split(' ');
            if (texts.length > 0) {
                var fontName = texts[0];
                if (fontName.endsWith('BO')) {
                    bold = true;
                    italic = true;
                }
                else if (fontName.endsWith('Bo')) {
                    bold = true;
                    italic = false;
                }
                else if (fontName.endsWith('Ob')) {
                    bold = false;
                    italic = true;
                }
            }
            if (texts.length > 1) {
                try {
                    fontSize = parseFloat(texts[1]);
                }
                catch (_a) { }
            }
        }
        // Center the text
        y = rectY + ((height - fontSize) / 2);
        var annot = {
            id: 0,
            x: x,
            y: y,
            rectX: rectX,
            rectY: rectY,
            width: width,
            height: height,
            text: annotation.fieldValue,
            fill: null,
            textAlign: align,
            description: '',
            bold: bold,
            italic: italic,
            fontSize: fontSize
        };
        console.log(annot);
        return annot;
    };
    return PDFParser;
}());
export { PDFParser };
