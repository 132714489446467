import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './guards/loggedin.guard';
import { AccountComponent } from './routes/account/account.component';
import { LoginComponent } from './routes/account/login/login.component';
import { AppBaseComponent } from './routes/app/appbase/appbase.component';
import { DocumentTypeComponent } from './routes/app/appbase/documentType/documentType.component';
import { DocumentTypeListComponent } from './routes/app/appbase/documentTypes/documentTypes.component';
import { TemplateListComponent } from './routes/app/appbase/templates/templates.component';
import { BaseComponent } from './routes/base/base.component';
import { NotFoundComponent } from './routes/not_found/not_found.component';
import { TestComponent } from './routes/base/test/test.component';
import { TemplateComponent } from './routes/app/appbase/template/template.component';
import { AddTemplateComponent } from './routes/app/appbase/addTemplate/addtemplate.component';

const appRoutes: Routes  = [

  // Pages for logging in
  {
    path: 'compte',
    component: AccountComponent,
    children: [
      {
        path: 'connexion',
        component: LoginComponent
      }
    ]
  },

  {
    path: 'testpdf',
    component: TestComponent,
  },

  // Validated section - user is connected
  {
    path: 'app',
    canActivate: [ LoggedInGuard ],
    component: AppBaseComponent,
    children: [
      {
        path: 'documentTypes',
        component: DocumentTypeListComponent,
        data: { title: 'Types de document' }
      },
      {
        path: 'documentType/:documentTypeId',
        component: DocumentTypeComponent
      },
      {
        path: 'templates',
        component: TemplateListComponent,
        data: { title: 'Templates' }
      },
      {
        path: 'addtemplate',
        component: AddTemplateComponent,
        data: { title: 'Ajouter un template' }
      },
      {
        path: 'template/:templateId',
        component: TemplateComponent
      },
      /*
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'traducteur',
        component: TraducteurComponent
      },
      {
        path: 'services',
        component: TraducteurServiceListComponent
      },
      {
        path: 'prestations',
        component: PrestationListComponent
      },
      {
        path: 'prestation/:prestationId',
        component: PrestationComponent
      }
      */

    ]
  },

  // Logged out page(s)
  {
    path: '',
    component: BaseComponent,
    children: [
    ]
  },



  /// Fallbacks
  { path: '', redirectTo: 'BaseComponent', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      // { enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
